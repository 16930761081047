import { ButtonAction } from '@/contracts/ButtonHeroAction'

export interface SectionTag {
  url?: string
  name: string
}

export interface SectionData {
  readonly sectionSideTitle?: string
  readonly sectionSideTitleEng?: string
  readonly sectionMainTitle: string
  readonly sectionMainTitleEng?: string
  readonly langType?: string
  readonly sectionDescription?: string
  readonly sectionDate?: string
  readonly sectionId?: string
  readonly sectionBackground?: string
  readonly sectionButtonsAlignment?: 'center' | 'left'
  readonly sectionButtons?: ButtonAction[]
  readonly sectionTags: SectionTag[]
  readonly sectionLink?: {
    readonly link?: string
    readonly arrowType?: string
    readonly value?: string
  }
  readonly sectionStyle?: string
}

export const emptySectionData = (): SectionData => {
  return {
    langType: '',
    sectionSideTitle: '',
    sectionSideTitleEng: '',
    sectionMainTitle: '',
    sectionMainTitleEng: '',
    sectionDescription: '',
    sectionDate: '',
    sectionId: '',
    sectionTags: [],
    sectionLink: {
      link: '',
      arrowType: '',
      value: '',
    },
  }
}
