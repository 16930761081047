







































import { computed, defineComponent, useStore } from '@nuxtjs/composition-api'
import { HeaderInfo } from '@/contracts/Settings'

export default defineComponent({
  name: 'HeaderNavMenuSsronly',
  setup() {
    const store = useStore()
    const headerInfo = computed(
      () => store.getters['settings/headerInfo'] as HeaderInfo
    )
    return {
      headerInfo,
    }
  },
})
