export const pageHead = (params = {}) => {
  const {
    siteName = '',
    title = '',
    description = '',
    image = null,
    schema = '',
    metaName = '',
    metaContent = '',
    canonicalHref = '',
    metaTags = [],
    metaIsCommingAdmin = false,
    meta = [],
    link = [],
  } = params || {}
  if (meta.length && link.length) {
    const headParams = {
      title,
      meta,
      link,
    }

    if (schema) {
      headParams.schema = schema
    }

    return headParams
  } else {
    const newDesk = (description || '').replace(/<\/?[^>]+(>|$)/g, '')
    const result = {
      title,
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: title || '',
        },
        {
          name: 'description',
          content: newDesk,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: newDesk,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: image,
        },
        {
          hid: 'og:site_name',
          property: 'og:site_name',
          content: siteName,
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: canonicalHref,
        },
        ...metaTags,
      ],
      link: [
        {
          rel: 'canonical',
          href: canonicalHref,
        },
      ],
    }

    if (schema) {
      result.schema = schema
    }

    let isExist = 0
    result.meta.forEach((item) => {
      if (metaIsCommingAdmin && metaName === item.name) {
        isExist += 1
        item = {
          content: metaContent,
          name: metaName,
        }
      }
    })

    if (!isExist) {
      result.meta.push({
        content: metaContent,
        name: metaName,
      })
    }
    return result
  }
}

export const siteHead = (params = {}) => {
  const { color = '#85754E', title = null, links = [], script = [] } = params
  return {
    title: title || process.env.npm_package_name || '',
    meta: [
      {
        charset: 'utf-8',
      },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1, viewport-fit=cover',
      },
      {
        name: 'format-detection',
        content: 'telephone=no',
      },
      {
        name: 'msapplication-TileColor',
        content: color,
      },
      {
        name: 'theme-color',
        content: color,
      },
    ],
    script: [...script],
    link: [
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: '/apple-touch-icon.png',
      },
      {
        rel: 'icon',
        sizes: '32x32',
        type: 'image/png',
        href: '/favicon-32x32.png',
      },
      {
        rel: 'icon',
        sizes: '16x16',
        type: 'image/png',
        href: '/favicon-16x16.png',
      },
      {
        rel: 'icon',
        type: 'image/ico',
        href: '/favicon.ico',
      },
      {
        rel: 'manifest',
        href: '/manifest.json',
      },
      {
        rel: 'mask-icon',
        href: '/safari-pinned-tab.svg',
        color,
      },
      ...links,
    ],
  }
}
