import {
  SettingsState,
  InterfaceKeys,
  SettingKeys,
  ThemeType,
} from '../contracts/Settings'

import { HTTP_REST_SERVER } from '@/modules/axios'
const getSiteDataObj = (state: SettingsState, key: SettingKeys): any =>
  state.siteData[state.curLang] ? state.siteData[state.curLang][key] : {}

const getSiteDataInterface = (
  state: SettingsState,
  key: InterfaceKeys
): string =>
  state.siteData[state.curLang] && state.siteData[state.curLang].interfaceText
    ? state.siteData[state.curLang].interfaceText[key]
    : ''

export const state = (): SettingsState => {
  return {
    siteData: {},
    curLang: 'ru',
    hideFooter: false,
    hideHeader: false,
    hideSurveyBtn: false,
    showSurveyPopup: false,
    theme: ThemeType.primary,
    isMapInited: false,
    iframeSrcs: [],
    notification: undefined,
    userLocation: {
      city: '',
      region: '',
      region_with_type: '',
    },
  }
}

export const getters = {
  getHideFooter: (state: SettingsState) => state.hideFooter,
  getHideHeader: (state: SettingsState) => state.hideHeader,
  getHideSurveyBtn: (state: SettingsState) => state.hideSurveyBtn,
  getMapIsInit: (state: SettingsState) => state.isMapInited,
  getIframeSrcs: (state: SettingsState) => state.iframeSrcs,
  uniquePageUrls: (state: SettingsState) =>
    getSiteDataObj(state, 'uniquePageUrls'),
  seo: (state: SettingsState) => getSiteDataObj(state, 'seo'),
  mainSectionCode: (state: SettingsState) =>
    getSiteDataObj(state, 'mainSectionCode'),
  footerInfo: (state: SettingsState) => getSiteDataObj(state, 'footerInfo'),
  headerInfo: (state: SettingsState) => getSiteDataObj(state, 'headerInfo'),
  popupBuyOnline: (state: SettingsState): string =>
    getSiteDataObj(state, 'popupBuyOnline'),
  btnRead: (state: SettingsState): string =>
    getSiteDataInterface(state, 'btnRead'),
  btnGo: (state: SettingsState): string => getSiteDataInterface(state, 'btnGo'),
  btnShowMore: (state: SettingsState): string =>
    getSiteDataInterface(state, 'btnShowMore'),
  btnRollUp: (state: SettingsState): string =>
    getSiteDataInterface(state, 'btnRollUp'),
  btnFormAgain: (state: SettingsState): string =>
    getSiteDataInterface(state, 'btnFormAgain'),
  textFailedForm: (state: SettingsState): string =>
    getSiteDataInterface(state, 'textFailedForm'),
  btnLoadMore: (state: SettingsState): string =>
    getSiteDataInterface(state, 'btnLoadMore'),
  oldBrowserTitle: (state: SettingsState): string =>
    getSiteDataInterface(state, 'oldBrowserTitle'),
  oldBrowserDescription: (state: SettingsState): string =>
    getSiteDataInterface(state, 'oldBrowserDescription'),
  oldBrowserLinkTitle: (state: SettingsState): string =>
    getSiteDataInterface(state, 'oldBrowserLinkTitle'),
  oldBrowserLinkUrl: (state: SettingsState): string =>
    getSiteDataInterface(state, 'oldBrowserLinkUrl'),
  cookie: (state: SettingsState): string => getSiteDataObj(state, 'cookie'),
  curLang: (state: SettingsState): string => state.curLang,
  survey: (state: SettingsState) => getSiteDataObj(state, 'survey'),
  notification: (state: SettingsState) => state.notification,
  holidayTheme: (state: SettingsState) => getSiteDataObj(state, 'holidayTheme'),
  allSiteNotification: (state: SettingsState) =>
    getSiteDataObj(state, 'allSiteNotification'),
  getShowSurveyPopup: (state: SettingsState) => state.showSurveyPopup,
  getSeoCityList: (state: SettingsState) => state.siteData.ru.seoCityList,
  getPopupCityListProperties: (state: SettingsState) =>
    state.siteData?.ru.popupCityList,
}

export const mutations = {
  SET_HIDE_FOOTER(state: SettingsState, payload: boolean) {
    state.hideFooter = payload
  },
  SET_INIT_MAP(state: SettingsState, payload: boolean) {
    state.isMapInited = payload
  },
  SET_HIDE_HEADER(state: SettingsState, payload: boolean) {
    state.hideHeader = payload
  },
  SET_HIDE_SURVEY_BTN(state: SettingsState, payload: boolean) {
    state.hideSurveyBtn = payload
  },
  SET_CURRENT_LANG(state: SettingsState, payload: string) {
    state.curLang = payload
  },
  TOGGLE_SURVEY(state: SettingsState, payload: boolean) {
    state.showSurveyPopup = payload
  },
  PUSH_IFRAME_SRC(state: SettingsState, payload: string) {
    state.iframeSrcs.push(payload)
  },

  SET_IFRAME_SRC(state: SettingsState, payload: Array<string>) {
    state.iframeSrcs = payload
  },

  SET_SITE_DATA_WITH_CURLANG(state: SettingsState, { key, data }) {
    state.siteData[state.curLang] = {
      ...state.siteData[state.curLang],
      [key]: data,
    }
  },

  SET_THEME(state: SettingsState, theme: ThemeType) {
    state.theme = theme
  },

  SET_NOTIFICATION(
    state: SettingsState,
    notification: Notification | undefined
  ) {
    state.notification = notification
  },

  SET_INITIAL_STORE(state: SettingsState, payload) {
    state.siteData = {
      ...payload,
    }
  },
}

export const actions = {
  async getSettings({ commit }: { commit: any }, { $config, error, req }) {
    try {
      const res = await HTTP_REST_SERVER.get(
        $config.SM_API_GET_INITIAL_STATE_PATH
      )
      delete res?.data.redirects
      Object.keys(res?.data).forEach((lang) => {
        delete res?.data[lang].pageList
      })
      commit('SET_INITIAL_STORE', res?.data)
    } catch (e) {
      error({ statusCode: e?.response?.status || 500 })
    }
  },
}
