<template>
  <f-layout :class="$options.themeClass">
    <template #page-header>
      <landing-header />
    </template>
    <template #page-content>
      <Nuxt />
      <landing-layout-blocks class="notifications--container" />
    </template>
    <template #page-footer>
      <FooterSection :first-section="false" :second-section="false" />
    </template>
  </f-layout>
</template>

<script>
import { ThemeType } from '../contracts/Settings'
import FooterSection from '@/views/FooterSection.vue'
import LandingLayoutBlocks from '@/components/LayoutBlocks.vue'
import LandingHeader from '@/views/ErrorHeader'

export default {
  components: {
    LandingHeader,
    FooterSection,
    LandingLayoutBlocks,
  },
  themeClass: `theme-${ThemeType.primary}`,
}
</script>

<style scoped lang="scss">
.notifications--container {
  height: 100%;
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9;
  pointer-events: none;
}
</style>
