import https from 'https'
import * as process from 'process'
import axios, { AxiosInstance } from 'axios'
import { Context } from '@nuxt/types'
import * as AxiosLogger from 'axios-logger'

const httpsAgent = new https.Agent({
  rejectUnauthorized: false,
})

// TODO Переделать на @nuxtjs/axios
export const HTTP_REST = (ctx: Context): AxiosInstance => {
  const customAxios = axios.create({
    baseURL: process.server
      ? process.env.API_PRIVATE_URL
      : ctx.$config.API_PUBLIC_URL,
    withCredentials: true,
    httpsAgent,
  })

  const returnSelf = <T>(param: T): T => param
  customAxios.interceptors.request.use(returnSelf, (err) => {
    if (process.server) {
      AxiosLogger.errorLogger(err, {
        dateFormat: 'dd.mm.yyHH:MM:ss',
        headers: false,
        data: true,
        params: true,
      })
    }

    throw err
  })
  customAxios.interceptors.response.use(returnSelf, (err) => {
    if (process.server) {
      AxiosLogger.errorLogger(err, {
        dateFormat: 'dd.mm.yyHH:MM:ss',
        headers: false,
        data: true,
        params: true,
      })
    }

    throw err
  })
  return customAxios
}
