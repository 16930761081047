











import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { ButtonAction } from '@/contracts/ButtonHeroAction'
import BlockButtonsItem from '@/components/BlockButtonsItem'

export default defineComponent({
  name: 'BlockButtons',
  components: { BlockButtonsItem },
  props: {
    buttonActions: {
      type: Array as PropType<ButtonAction[]>,
      default: () => [],
    },
    alignment: {
      type: String as PropType<'left' | 'center' | 'right'>,
      default: 'left',
    },
  },
  setup(_, { emit }) {
    const onButtonClick = (button: ButtonAction) => {
      emit('button-click', button)
    }

    return {
      onButtonClick,
    }
  },
})
