import { Locale, StateLocale } from '../contracts/StateLocale'

export const state = (): StateLocale => {
  return {
    locales: [Locale.Ru, Locale.En],
    currentLocale: Locale.NotSet,
  }
}

export const getters = {
  getCurrentLocale: (state: StateLocale) => state.currentLocale,
}

export const mutations = {
  SET_LOCALE(state: StateLocale, locale: Locale) {
    if (!state.locales.includes(locale))
      throw new Error('ошибка установки локали в сторе!')

    state.currentLocale = locale
  },
}

export const actions = {
  setLocale({ commit }: { commit: Function }, locale: Locale): void {
    commit('SET_LOCALE', locale)
  },
}
