













import {
  defineComponent,
  useStore,
  computed,
  SetupContext,
} from '@nuxtjs/composition-api'
import { PopupBuyOnlineState } from '@/contracts/PopupBuyOnlineState'

export default defineComponent({
  name: 'ButtonBuyOnline',
  setup(_props, ctx: SetupContext) {
    const { commit, state }: { state: PopupBuyOnlineState; commit: Function } =
      useStore()

    const showButton = computed(() => state.popupBuyOnline.showButton)
    const showButtonFooter = computed(
      () => ctx.root.$store.getters['buttonAgent/GET_SHOW_BUTTON']
    )
    const label = computed(
      () => ctx.root.$store.getters['settings/popupBuyOnline'].title
    )
    const togglePopup = () => commit('popupBuyOnline/SET_SHOW_POPUP', true)

    return {
      showButton,
      togglePopup,
      label,
      showButtonFooter,
    }
  },
})
