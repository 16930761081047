






















































import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { defineComponent, ref } from '@nuxtjs/composition-api'
import { VueSlideToggle } from 'vue-slide-toggle'
import Link from '@/components/Link.vue'

@Component
class Accordeon extends Vue {
  @Prop() readonly title: String = ''
  @Prop() readonly url: String = ''
  @Prop() readonly isActive: Boolean = false
  @Prop() readonly urlText: String = ''
  @Prop() readonly categories: ReadonlyArray<any> = []
  static options: any
}

export default defineComponent({
  components: { Link, VueSlideToggle },
  props: Accordeon.options.props,
  setup(_props: any, { emit }: { emit: Function }) {
    const value = ref(false)
    const duration = ref(300)

    const onClick = () => {
      value.value = !value.value
    }
    const onClickTab = (event: Event, index: any) => {
      emit('on-click', event.target, index, true)
    }
    const closeAllPopups = () => {
      emit('close-all-popups')
    }

    return {
      value,
      duration,
      onClick,
      onClickTab,
      closeAllPopups,
    }
  },
})
