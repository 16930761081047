




import Vue from 'vue'
import Component from 'vue-class-component'
import Header from '@/components/Header/index.vue'

@Component({
  components: {
    Header,
  },
})
export default class HeaderSection extends Vue {}
