export const state = () => {
  return {
    breadcrumbs: [],
    isVisible: true,
  }
}

export const getters = {
  GET_BREADCRUMBS: (state: any) => state.breadcrumbs,
  GET_BREADCRUMBS_VISIBILITY: (state: any) => state.isVisible,
}

export const mutations = {
  SET_BREADCRUMBS(state: any, text: any) {
    state.breadcrumbs = text
  },
  SET_BREADCRUMBS_VISIBILITY(state: any, visibility: boolean) {
    state.isVisible = visibility
  },
}

export const actions = {
  create({ commit }: { commit: Function }, data: any): void {
    commit(
      'SET_BREADCRUMBS',
      data.map((page) => {
        const { name, fullPath, isHidden } = page
        return {
          text: name,
          url: fullPath,
          isHidden,
        }
      })
    )
  },
}
