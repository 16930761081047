import { Element, Section } from '@/apollo/composition-functions'
import { ButtonAction } from '@/contracts/ButtonHeroAction'
import { UniqueTypePages } from '@/contracts/PageTypes'

export interface Notification {
  notificationContent?: string
  notificationStartTime: string | null
  notificationEndTime: string | null
  notificationActivity?: boolean
  notificationIcon?: string
  notificationBackgroundColor?: string
  notificationContentColor?: string
}

export interface SiteNotification {
  notificationContent?: string
  notificationStartTime: string | null
  notificationEndTime: string | null
  notificationActivity?: boolean
  notificationTitle?: string
  notificationImage?: string
  notificationButton?: ButtonAction[]
  notificationBackgroundColor?: string
  notificationTitleColor?: string
  notificationContentColor?: string
  notificationPosition:
    | 'top-left'
    | 'top-center'
    | 'top-right'
    | 'middle-left'
    | 'middle-center'
    | 'middle-right'
    | 'bottom-left'
    | 'bottom-center'
    | 'bottom-right'
}

export interface FooterInfo {
  footerNavLinks: ReadonlyArray<any>
  sideNavLinks: ReadonlyArray<any>
  docLinks: ReadonlyArray<any>
  socials: ReadonlyArray<any>
  contacts: ReadonlyArray<any>
  contactsLink: ReadonlyArray<any>
  externalResources: ReadonlyArray<any>
  copyright: Object
  language: Object
  accessibilityButton: Object
}

export interface HeaderInfo {
  headerNavLinks: ReadonlyArray<any>
  tabs: ReadonlyArray<any>
  productList: ReadonlyArray<any>
  search: Object
  offices: Object
  support: Object
  login: Object
  logotype: Object
  actions: ReadonlyArray<any>
  insuranceCase: Object
  activateOrCheckPolicy: Object
}

export type InterfaceKeys =
  | 'btnRead'
  | 'btnGo'
  | 'btnShowMore'
  | 'btnRollUp'
  | 'btnFormAgain'
  | 'textFailedForm'
  | 'btnLoadMore'
  | 'oldBrowserTitle'
  | 'oldBrowserDescription'
  | 'oldBrowserLinkTitle'
  | 'oldBrowserLinkUrl'

export type InterfaceText = {
  [key in InterfaceKeys]: string
}

export interface Seo {
  title: string
  description: string
  siteName: string
  image: string
  schema: string
}

export interface PopupBuyOnline {
  title: string
  list: ReadonlyArray<any>
}

export interface ElementWithParent extends Element {
  name: string
  fullPath: string
  parent: ElementWithParent | string | undefined
  code: string
}

export interface AdaptedPageItem {
  child?: ReadonlyArray<AdaptedPageItem>
  parent: ElementWithParent | string | undefined
  code: string
  fullPath: string
  id: string
  isShowHeader?: boolean | undefined
  name: string
  updatedAt: string
  showButtonBuyOnline?: boolean | undefined
  type?: string | undefined
  uniquePages?: string
  locationCity?: []
  locationRegion?: []
  hideInDropdownMenu: boolean
}

export interface SiteLangSettings {
  headerInfo: HeaderInfo
  footerInfo: FooterInfo
  interfaceText: InterfaceText
  seo: Seo
  uniquePageUrls: Record<UniqueTypePages, string>
  popupBuyOnline: PopupBuyOnline
  mainSectionCode: string
  pageList: ReadonlyArray<AdaptedPageItem>
  notification?: Notification
}
export type SettingKeys =
  | 'headerInfo'
  | 'footerInfo'
  | 'interfaceText'
  | 'seo'
  | 'popupBuyOnline'
  | 'pageList'
  | 'mainSectionCode'
  | 'cookie'
  | 'survey'
  | 'notification'
  | 'allSiteNotification'
  | 'holidayTheme'
  | 'uniquePageUrls'

export type SiteData = {
  [key: string]: SiteLangSettings
}

export interface AppLinks {
  appStore?: string
  playMarket?: string
  desktop?: string
}

export interface LinksTelemed {
  ios?: string
  android?: string
  desktop?: string
}

export interface Cookie {
  text?: string
  textButton?: string
  footerText?: string
}

export interface Survey {
  title?: string
  buttonName?: string
  frameLink?: string
  isShowButton?: boolean | undefined
}

export interface IHolidayTheme {
  themeLogo: string
  themeSide: string
  themeDelimiter: string
  themeMainSection: string
  themeActive: boolean
}

export interface AdaptedSetting {
  seo: Seo
  mainSectionCode: string
  mainSectionId: string
  code: string
  desktopQr: string
  appLinks: AppLinks
  linksTelemed: LinksTelemed
  interfaceText: InterfaceText
  cookie: Cookie
  survey: Survey
  holidayTheme: IHolidayTheme
  notification?: Notification
}

export interface IUserLocation {
  city: string
  region: string
  region_with_type: string
}

export enum ThemeType {
  primary = 'primary',
  vip = 'vip',
}

export interface IDadataResponse {
  value: string
  data: {
    city: string
    region: string
    region_with_type: string
  }
}

export interface SettingsState {
  siteData: SiteData
  curLang: string
  theme: ThemeType
  hideFooter: boolean
  hideHeader: boolean
  hideSurveyBtn: boolean
  isMapInited?: boolean
  showSurveyPopup: boolean
  iframeSrcs: Array<string>
  userLocation: IUserLocation
  notification?: Notification
}

export type Redirect = {
  id: string
  name: string
  code: null | string
  __typename?: string
  propertyValuesEnum: []
  propertyValuesRelation?: []
}

export type AdaptedLangPages = {
  [key: string]: Array<AdaptedPageItem>
}

export interface SectionWithParent extends Section {
  elements: Array<ElementWithParent>
}
