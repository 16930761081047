import isoLang from 'iso-639-1'

export default function ({ route: { path }, store: { commit } }) {
  const code = path.split('/')[1]
  if (isoLang.getName(code)) {
    commit('settings/SET_CURRENT_LANG', code)
    return
  }
  commit('settings/SET_CURRENT_LANG', 'ru')
}
