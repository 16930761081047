export const state = () => {
  return {
    showButton: false,
  }
}
export const getters = {
  GET_SHOW_BUTTON(state) {
    return state.showButton
  },
}

export const mutations = {
  TOGGLE_BUTTON(state, param) {
    state.showButton = param
  },
}
