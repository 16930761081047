export const state = () => ({
  categories: [],
})
export const getters = {
  GET_CATEGORIES: (state) => state.categories,
}
export const mutations = {
  SET_CATEGORIES: (state, payload: unknown) => {
    state.categories = payload
  },
}
