




















import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { defineComponent, ref } from '@nuxtjs/composition-api'
import Accordeon from '@/components/Header/Accordeon.vue'

@Component
class AccordeonList extends Vue {
  @Prop() readonly list: ReadonlyArray<any> = []
  static options: any
}

export default defineComponent({
  name: 'AccordeonList',
  components: {
    Accordeon,
  },
  props: AccordeonList.options.props,
  setup(_props: any, { emit }: { emit: Function }) {
    const activeId = ref(false)
    const onClick = (element: any, index: number, isMobileTablet: boolean) => {
      emit('on-click', element, index, isMobileTablet)
    }
    const closeAllPopups = () => {
      emit('close-all-popups')
    }
    const onCardClick = (id: any) => {
      activeId.value = activeId.value === id ? null : id
    }
    return {
      onClick,
      onCardClick,
      closeAllPopups,
      activeId,
    }
  },
})
