export const state = () => ({
  путь: {
    стартовый_запрос: { запрос: '', ответ: [] },
    второй_запрос: { запрос: '', ответ: [] },
    третий_запрос: { запрос: '', ответ: [] },
    четвертый_запрос: { запрос: '', ответ: [] },
    пятый_запрос: { запрос: '', ответ: [] },
    первое_нажатие_кнопки: { запрос: '', ответ: [] },
    второе_нажатие_кнопки: { запрос: '', ответ: [] },
    третье_нажатие_кнопки: { запрос: '', ответ: [] },
    четвертое_нажатие_кнопки: { запрос: '', ответ: [] },
    пятое_нажатие_кнопки: { запрос: '', ответ: [] },
    не_найдено: { запрос: '', ответ: '' },
  },
  searchCounter: 0,
  btnCounter: 0,
})

const requestKeys = [
  'стартовый_запрос',
  'второй_запрос',
  'третий_запрос',
  'четвертый_запрос',
  'пятый_запрос',
]

const buttonKeys = [
  'первое_нажатие_кнопки',
  'второе_нажатие_кнопки',
  'третье_нажатие_кнопки',
  'четвертое_нажатие_кнопки',
  'пятое_нажатие_кнопки',
]

export const mutations = {
  ADD_SEARCH_COUNTER(state) {
    state.searchCounter += 1
  },
  ADD_BTN_COUNTER(state) {
    state.btnCounter += 1
  },

  // Увеличиваем счетчик запросов и добавляем новый запрос в массив
  ADD_SEARCH(state, payload) {
    const { question, answer } = payload

    const index = state.searchCounter - 1
    if (index >= 0 && index < requestKeys.length) {
      const key = requestKeys[index]
      state.путь[key].запрос = question
      state.путь[key].ответ = answer
    }
  },

  // Добавляем информацию о нажатой кнопке в текущий запрос
  ADD_BUTTON(state, payload) {
    const { question, answer } = payload
    const index = state.btnCounter - 1
    if (index >= 0 && index < buttonKeys.length) {
      const key = buttonKeys[index]
      state.путь[key].запрос = question
      state.путь[key].ответ = answer
    }
  },

  ADD_NOT_FOUND(state, payload) {
    const { question, answer } = payload
    state.путь.не_найдено.запрос = question
    state.путь.не_найдено.ответ = answer
  },

  // Сброс данных метрики
  RESET_METRICS_DATA(state) {
    state.путь = {
      стартовый_запрос: { запрос: '', ответ: [] },
      второй_запрос: { запрос: '', ответ: [] },
      третий_запрос: { запрос: '', ответ: [] },
      четвертый_запрос: { запрос: '', ответ: [] },
      пятый_запрос: { запрос: '', ответ: [] },
      первое_нажатие_кнопки: { запрос: '', ответ: [] },
      второе_нажатие_кнопки: { запрос: '', ответ: [] },
      третье_нажатие_кнопки: { запрос: '', ответ: [] },
      четвертое_нажатие_кнопки: { запрос: '', ответ: [] },
      пятое_нажатие_кнопки: { запрос: '', ответ: [] },
      не_найдено: { запрос: '', ответ: '' },
    }
    state.searchCounter = 0
    state.btnCounter = 0
  },
}

export const actions = {
  // Отправка данных в метрику
  async sendMetrics({ state, commit }) {
    await window.ym(396811, 'reachGoal', 'search_way', state.путь)

    // Сброс данных после отправки (опционально)
    commit('RESET_METRICS_DATA')
  },
}
