

export default () => {
    if (process.server) {
        return;
    }

    const detect = () => typeof globalThis === 'object';

    if (detect()) {
        return;
    }

    return new Promise((resolve, reject) => {
        require.ensure([
            "@ungap/global-this"
        ], function (require) {
            const polyfill = require("@ungap/global-this");

            resolve();
        });
    });
}