type Block = {
  letter: string
  list: string[]
}
export const parseCitiesToAlphabetList = (array: string[]) => {
  const letters = new Set()
  array.map((c: string) => letters.add(c[0]))
  return Array.from(letters)
    .sort((a, b) => a.charCodeAt(0) - b.charCodeAt(0))
    .map((l: string): Block[] => {
      return {
        letter: l,
        list: array.filter((city: string) => city[0] === l),
      }
    })
}
