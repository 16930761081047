

































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { defineComponent, SetupContext } from '@vue/composition-api'
import NavigationMenu from '@/components/Header/NavigationMenu.vue'
import MenuLogotype from '@/components/Header/MenuLogotype.vue'
import MobileMenu from '@/components/Header/MobileMenu.vue'
import MenuRight from '@/components/Header/MenuRight.vue'

@Component
class SectionNavigation extends Vue {
  @Prop({ default: () => {} }) readonly data: Object | undefined
  @Prop({ default: false }) readonly activeMobileMenu: Boolean | undefined
  @Prop({ default: false }) readonly activeTabMenu: Boolean | undefined
  @Prop({ default: false }) readonly showPopup: Boolean | undefined
  @Prop({ default: false }) readonly isMobileSearch: Boolean | undefined
  @Prop({ default: '' }) readonly parentActiveId: string | undefined
  static options: any
}

const sectionNavigationProps = SectionNavigation.options.props

export default defineComponent({
  name: 'SectionNavigation',
  components: {
    NavigationMenu,
    MenuLogotype,
    MobileMenu,
    MenuRight,
  },
  props: { ...sectionNavigationProps },
  setup(_props, ctx: SetupContext) {
    const changeCategories = (url: string) => {
      ctx.emit('change-categories', url)
    }
    const togglePopup = () => {
      ctx.emit('toggle-popup')
    }
    const onClickBurger = () => {
      ctx.emit('toggle-burger')
    }
    const closeTab = () => {
      ctx.emit('close-tab')
    }
    const closeAllPopups = () => {
      ctx.emit('close-all-popups')
    }
    const startSearch = () => {
      ctx.emit('start-search')
    }
    return {
      togglePopup,
      onClickBurger,
      changeCategories,
      closeTab,
      startSearch,
      closeAllPopups,
    }
  },
})
