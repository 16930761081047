

































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import CardImage from '@/components/Cards/CardImage.vue'
import { CardImage as ICardImage } from '@/contracts/Cards'

export default defineComponent({
  name: 'BlockCardImageGrid',
  components: { CardImage },
  props: {
    list: {
      type: Array as PropType<ICardImage[]>,
      default: () => [],
    },
  },
})
