
import { computed, defineComponent, useStore } from '@nuxtjs/composition-api'
import { parseCitiesToAlphabetList } from '@/composition/parseCitiesToAlphabetList'

export default defineComponent({
  name: 'BlockListSeoCities',
  setup(_, { emit }) {
    const { getters } = useStore()
    const cities = computed(() =>
      parseCitiesToAlphabetList(getters['settings/getSeoCityList'])
    )
    const onSelectCity = (city) => {
      emit('select', city)
    }
    return {
      onSelectCity,
      cities,
    }
  },
})
