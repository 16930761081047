export class Logger {
  static log(...args: any[]): void {
    if (Logger.isConsoleShow()) {
      // eslint-disable-next-line no-console
      console.log(args)
    }
  }

  static dir(...args: any[]): void {
    if (Logger.isConsoleShow()) {
      // eslint-disable-next-line no-console
      console.dir(args)
    }
  }

  static error(...args: any[]): void {
    if (Logger.isConsoleShow()) {
      // eslint-disable-next-line no-console
      console.error(args)
    }
  }

  static warn(...args: any[]): void {
    if (Logger.isConsoleShow()) {
      // eslint-disable-next-line no-console
      console.warn(args)
    }
  }

  static assert(...args: any[]): void {
    if (Logger.isConsoleShow()) {
      // eslint-disable-next-line no-console
      console.assert(args)
    }
  }

  static info(...args: any[]): void {
    if (Logger.isConsoleShow()) {
      // eslint-disable-next-line no-console
      console.info(args)
    }
  }

  private static isConsoleShow() {
    return process.env.NODE_ENV !== 'production' || process.server
  }
}
