




















import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { defineComponent } from '@nuxtjs/composition-api'
import Link from '@/components/Link.vue'

@Component
class NavigationMenu extends Vue {
  @Prop() readonly list: ReadonlyArray<any> = []
  @Prop({ default: '' }) readonly parentActiveId: string | undefined
  static options: any
}
export default defineComponent({
  name: 'NavigationMenu',
  components: { Link },
  props: NavigationMenu.options.props,
  setup() {},
})
