export const yaCounter = (params: string, gtm: string): void => {
  if (window && window.ym && gtm) {
    window.ym(396811, 'reachGoal', params)
  }
}

export const yaHitPage = () => {
  if (window && window.ym) {
    window.ym(396811, 'hit', window.location.href)
  }
}

export const yaReach = (param: string) => {
  if (window && window.ym) {
    window.ym(396811, 'reachGoal', param)
  }
}

export const createYaSearchBot = () => {
  let stepCounter = 1

  const yaSearchBot = (goal: string, params: string) => {
    if (window && window.ym && stepCounter < 5) {
      window.ym(99530371, 'reachGoal', goal, params)
      stepCounter += 1
    }
  }

  // Функция для сброса шагов
  const resetYaSearchBotCounter = () => {
    stepCounter = 0
  }

  // Функция для получения текущего шага
  const getStepCounter = () => console.log(stepCounter)

  return { yaSearchBot, resetYaSearchBotCounter, getStepCounter }
}
