import https from 'https'
import axios, { AxiosInstance } from 'axios'
import * as AxiosLogger from 'axios-logger'

const httpsAgent = new https.Agent({
  rejectUnauthorized: false,
})

const customAxios: AxiosInstance = axios.create({
  baseURL:
    (process.env.SM_API_URL || '') +
    (process.env.BASE_PATH || '') +
    process.env.SM_API_ROOT,
  withCredentials: true,
  httpsAgent,
})

const returnSelf = <T>(param: T): T => param
customAxios.interceptors.request.use(
  (config) => {
    return config
  },
  (err) => {
    return process.browser
      ? err
      : AxiosLogger.errorLogger(err, {
          dateFormat: 'dd.mm.yy HH:MM:ss',
          headers: false,
          data: true,
          params: true,
        })
  }
)

customAxios.interceptors.response.use(returnSelf, (err) => {
  return process.browser
    ? err
    : AxiosLogger.errorLogger(err, {
        dateFormat: 'dd.mm.yy HH:MM:ss',
        headers: false,
        data: true,
        params: true,
      })
})

export const HTTP_REST_SERVER = customAxios
