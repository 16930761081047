export const state = (): any => {
  return {
    isShown: false,
    cityName: 'Москва',
    searchText: null,
  }
}

export const getters = {
  getShownStatus: (state: any) => state.isShown,
  getCity: (state: any) => state.cityName,
  getSearchText: (state: any) => state.searchText,
}

export const mutations = {
  SET_SHOWN(state: any, value: boolean) {
    state.isShown = value
  },
  SET_CITY_NAME(state: any, value: string) {
    state.cityName = value
  },
  SET_SEARCH_TEXT(state: any, value: string | null) {
    state.searchText = value
  },
}

export const actions = {
  closePopup({ commit }: { commit: Function }) {
    commit('SET_SHOWN', false)
  },
}
