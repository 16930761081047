type State = {
  userPositionCoordinates: {
    lat: number
    long: number
  } | null
}

export const state = (): State => {
  return {
    userPositionCoordinates: null,
  }
}

export const getters = {
  GET_USER_POSITION_COORDINATES(state: State) {
    return state.userPositionCoordinates
  },
}

export const mutations = {
  SET_USER_POSITION_COORDINATES(state: State, { lat, long }) {
    state.userPositionCoordinates = { lat, long }
  },
}
