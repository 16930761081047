
















































































































































































































































































































import {
  computed,
  SetupContext,
  defineComponent,
} from '@nuxtjs/composition-api'
import { FooterInfo } from '@/contracts/Settings'
import ContactBlock from '@/components/ContactBlock.vue'
import BlockText from '@/components/BlockText.vue'
import Link from '@/components/Link.vue'

export default defineComponent({
  name: 'Footer',
  components: {
    ContactBlock,
    Link,
    BlockText,
  },
  setup(_props, ctx: SetupContext) {
    const footerInfo = computed(
      () => ctx.root.$store.getters['settings/footerInfo'] as FooterInfo
    )
    const cookie = computed(() => ctx.root.$store.getters['settings/cookie'])
    const isMobile = computed(
      () => ctx.root.$store.getters['mediaQuery/isMobileMq']
    )
    const isDesktop = computed(
      () => ctx.root.$store.getters['mediaQuery/isDesktopMq']
    )
    return {
      footerInfo,
      cookie,
      isMobile,
      isDesktop,
    }
  },
})
