import Vue from 'vue'
import setInnerHtml from '../helpers/sanitizeHtml'

Vue.directive('html-safe', {
  inserted(el, html, node) {
    setInnerHtml(el, html, node)
  },

  update(el, html, node) {
    setInnerHtml(el, html, node)
  },
})
