
















import { defineComponent, useStore, ref, watch } from '@nuxtjs/composition-api'
import { computed } from '@vue/composition-api'
import { useHeaderHeight } from '@/composition/headerHeight'

export default defineComponent({
  name: 'SurveyButton',
  setup() {
    const { getters } = useStore()

    const { headerHeight } = useHeaderHeight()

    const hasEOsago = ref(false)
    const timeoutId = ref(0)
    const showButton = ref(true)

    const indented = computed(() => getters['intendet/GET_INTENDET'])
    const surveyState = computed(() => getters['settings/survey'])
    const isMobile = computed<boolean>(
      (): boolean => getters['mediaQuery/isMobileMq']
    )
    const iframeSrcs = computed(() => getters['settings/getIframeSrcs'])

    const mobileStyles = computed(() => ({
      bottom: '0',
      right: '2.4rem',
      transform: 'rotate(0) translateY(0)',
      'transform-origin': 'bottom right',
    }))
    const desktopStyles = computed(() => ({
      top: indented.value ? `calc(50% - ${headerHeight.value}px)` : '50%',
    }))

    const timeoutHandle = () => {
      showButton.value = true
    }

    watch(iframeSrcs, () => {
      clearTimeout(timeoutId.value)
      if (
        isMobile.value &&
        iframeSrcs.value.find((iframeSrc) => iframeSrc.includes('eOsago'))
      ) {
        hasEOsago.value = true
        showButton.value = false
        timeoutId.value = setTimeout(timeoutHandle, 30000)
      }
    })

    return {
      hasEOsago,
      showButton,
      isMobile,
      desktopStyles,
      mobileStyles,
      surveyState,
    }
  },
  methods: {
    handleClick() {
      window.ttgrafSetEmbedParams({
        id: 543476,
        scrollToInvalid: true,
      })
      document.body.classList.add('testograph-open')
      window.testograf = {
        params: {
          alias: window.location.href,
        },
      }
      this.$store.commit('settings/TOGGLE_SURVEY', true)
    },
    handleClose() {
      this.$store.commit('settings/TOGGLE_SURVEY', false)
      document.body.classList.remove('testograph-open')
    },
  },
})
