













import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'SearchSuggestions',
  props: {
    suggestions: {
      type: Array,
      default: () => [],
    },
    currentText: {
      type: String,
      default: '',
    },
  },
  setup(_, { emit }) {
    const selectSuggestion = (suggestion: string) => {
      emit('selectSuggestion', suggestion)
    }

    return {
      selectSuggestion,
    }
  },
})
