

































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { defineComponent, SetupContext } from '@vue/composition-api'
import CategoryList from '@/components/Header/CategoryList.vue'
import ActionList from '@/components/Header/ActionList.vue'

@Component
class SectionCategories extends Vue {
  @Prop({ default: () => [] }) readonly categories:
    | ReadonlyArray<any>
    | undefined

  @Prop({ default: '' }) readonly pagePath: string | undefined
  @Prop({ default: () => {} }) readonly data: Object | undefined
  @Prop({ default: true }) readonly isDisabledActiveTab: Boolean | undefined
  @Prop({ default: false }) readonly isShowActivated: Boolean = false
  static options: any
}

const sectionCategoriesProps = SectionCategories.options.props

export default defineComponent({
  name: 'SectionCategories',
  components: {
    CategoryList,
    ActionList,
  },
  props: { ...sectionCategoriesProps },
  setup(_props, ctx: SetupContext) {
    const setActiveTab = (element: any, index: number) => {
      ctx.emit('on-click', element, index)
    }
    const closeTab = () => {
      ctx.emit('close-tab')
    }
    const closeAllPopups = (url = '') => {
      ctx.emit('close-all-popups', url)
    }
    return {
      setActiveTab,
      closeTab,
      closeAllPopups,
    }
  },
})
