var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrapper"},[_c('client-only',[(
        _vm.notification &&
        _vm.notification.notificationContent &&
        _vm.notification.notificationActivity &&
        _vm.notificationDateIsActive &&
        !_vm.notificationAccept
      )?_c('block-works-notify',{attrs:{"notify":_vm.notification},on:{"close":_vm.closeNotificationHandle}}):_vm._e()],1),_vm._v(" "),(!_vm.hideHeader)?_c('header',{staticClass:"page-header"},[_vm._t("page-header")],2):_vm._e(),_vm._v(" "),_c('main',{staticClass:"page-content"},[(_vm.holidayTheme.themeActive && _vm.holidayTheme.themeMainSection)?_c('f-picture',{staticClass:"holiday-theme__main-section holiday-theme__main-section_left",attrs:{"is-cover":false,"sizes":[980, 323],"quality":100,"source-mob":_vm.holidayTheme.themeMainSection,"source-desk":_vm.holidayTheme.themeMainSection}}):_vm._e(),_vm._v(" "),(_vm.holidayTheme.themeActive && _vm.holidayTheme.themeMainSection)?_c('f-picture',{staticClass:"holiday-theme__main-section holiday-theme__main-section_right",attrs:{"is-cover":false,"sizes":[980, 323],"quality":100,"source-mob":_vm.holidayTheme.themeMainSection,"source-desk":_vm.holidayTheme.themeMainSection}}):_vm._e(),_vm._v(" "),_vm._t("page-content")],2),_vm._v(" "),(!_vm.hideFooter)?_c('footer',{staticClass:"page-footer"},[(_vm.holidayTheme.themeActive && _vm.holidayTheme.themeSide)?_c('div',{staticClass:"holiday-theme__background-img holiday-theme__background-img-left",style:({ backgroundImage: ("url(" + (_vm.holidayTheme.themeSide) + ")") })}):_vm._e(),_vm._v(" "),_vm._t("page-footer"),_vm._v(" "),(_vm.holidayTheme.themeActive && _vm.holidayTheme.themeSide)?_c('div',{staticClass:"\n        holiday-theme__background-img holiday-theme__background-img-right\n      ",style:({ backgroundImage: ("url(" + (_vm.holidayTheme.themeSide) + ")") })}):_vm._e()],2):_vm._e(),_vm._v(" "),_c('layout-blocks'),_vm._v(" "),_c('client-only',[(
        _vm.allSiteNotification &&
        _vm.allSiteNotification.notificationActivity &&
        _vm.siteNotificationDateIsActive &&
        !_vm.siteNotificationAccept
      )?_c('block-site-notification',{attrs:{"notification":_vm.allSiteNotification},on:{"close":_vm.closeSiteNotificationHandle}}):_vm._e()],1),_vm._v(" "),_c('client-only',[(_vm.showDebug)?_c('f-debug',{attrs:{"root-selector":"#__layout"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }