































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  SetupContext,
  nextTick,
} from '@vue/composition-api'
import MenuLogotype from '@/components/Header/MenuLogotype.vue'
import { SEARCH_LENGTH } from '@/composition/searchDataRequest'

@Component
class SectionNavigation extends Vue {
  @Prop({ default: () => {} }) readonly data: Object | undefined
  @Prop({ default: false }) readonly activeMobileMenu: Boolean | undefined
  @Prop({ default: false }) readonly activeTabMenu: Boolean | undefined
  @Prop({ default: false }) readonly showPopup: Boolean | undefined
  @Prop({ default: '' }) readonly parentActiveId: string | undefined
  static options: any
}

const sectionNavigationProps = SectionNavigation.options.props

export default defineComponent({
  name: 'SectionNavigation',
  components: {
    MenuLogotype,
  },
  props: { ...sectionNavigationProps },
  setup(_props, ctx: SetupContext) {
    const model = ref(null)
    const searchString = computed(() => {
      return model.value ? `?search=${model.value}` : ''
    })
    const closeSearch = () => {
      ctx.emit('close-search')
    }
    const goToSearch = () => {
      ctx.root._router.push({ path: '/search', query: { search: model.value } })
      ctx.emit('close-search')
    }

    onMounted(() => {
      nextTick(() => {
        ctx.refs['search-field'].focus()
      })
    })

    return {
      closeSearch,
      goToSearch,
      searchString,
      model,
      searchMaxLength: SEARCH_LENGTH,
    }
  },
})
