
















import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { defineComponent } from '@nuxtjs/composition-api'
import { getHrefByLinkType } from '@/composition/getHrefByLinkType'

@Component
class ContactBlock extends Vue {
  @Prop() readonly contact: Object = {}
  static options: any
}

export default defineComponent({
  name: 'ContactBlock',
  props: ContactBlock.options.props,
  setup() {
    return { getHrefByLinkType }
  },
})
