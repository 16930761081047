





















































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import {
  defineComponent,
  SetupContext,
  computed,
  ref,
  onMounted,
  nextTick,
} from '@vue/composition-api'
import { useHeaderHeight } from '@/composition/headerHeight'
import PopupFade from '@/components/PopupFade.vue'
import { SEARCH_LENGTH } from '@/composition/searchDataRequest'

const DATA_TEXT = {
  backBtnText: 'Вернуться в меню',
  placeholder: 'Что вы хотите найти?',
  searchMaxLength: SEARCH_LENGTH,
}

@Component
class SectionMobileNavigation extends Vue {
  @Prop({ default: false }) readonly isStartSearch: Boolean | undefined
  @Prop({ default: false }) readonly isMobileTablet: Boolean | undefined
  @Prop() readonly backToMenu: any | undefined
  @Prop({ default: () => {} }) readonly data: Object | undefined
  static options: any
}

const sectionMobileNavigationProps = SectionMobileNavigation.options.props

export default defineComponent({
  name: 'SectionMobileNavigation',
  components: {
    PopupFade,
  },
  props: { ...sectionMobileNavigationProps },
  setup(_props, ctx: SetupContext) {
    const { headerHeight } = useHeaderHeight()
    const popupStyles = computed(() => {
      return {
        top: `${headerHeight.value}px`,
        height: `calc(100vh - ${headerHeight.value}px)`,
      }
    })
    const model = ref(null)

    const goToSearch = () => {
      ctx.root._router.push({ path: '/search', query: { search: model.value } })
      ctx.emit('close-search', { closePopups: true })
    }

    onMounted(() => {
      nextTick(() => {
        ctx.refs['search-field'].focus()
      })
    })

    return {
      popupStyles,
      model,
      goToSearch,
      dataText: DATA_TEXT,
    }
  },
})
