import { defineNuxtPlugin } from '@nuxtjs/composition-api'
import Api from '~/api'

declare module '@nuxt/types' {
  interface Context {
    $api: Api
  }
}

export default defineNuxtPlugin((ctx, inject) => {
  const api = new Api(ctx)
  ctx.$api = api

  inject('api', api)
})
