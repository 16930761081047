export const HEADERS = {
  'Access-Control-Allow-Origin': '*',
}

// auth
export const API_ROUTES_GET_VEHICLE_MODELS = 'api/v1/rgs/export/car-models'
export const API_ROUTES_CHECK_DOCUMENTS = '/api/v1/rgs/complex-documents/send'

// office
export const API_ROUTES_GET_REGIONS = '/api/v1/rgs/export/regions'
export const API_ROUTES_GET_CITIES = '/api/v1/rgs/other/cities'
export const API_ROUTES_GET_OFFICES = '/api/v1/rgs/export/offices'
export const API_ROUTES_GET_OFFICES_BY_BOUNDS = '/api/v1/rgs/export/offices'
export const API_ROUTES_GET_AGENTS_BY_BOUNDS = '/api/v1/rgs/export/agents-map'
export const API_ROUTES_GET_STOAS = '/api/v1/rgs/stoa/list'
export const API_ROUTES_GET_INSURANCE_PERIODS =
  '/api/v1/rgs/other/opo-accidients'
export const API_ROUTES_CHECK_STATUS = '/api/v1/rgs/claim/status'
export const API_ROUTES_CHECK_AGENT = '/api/v1/rgs/export/brokers-agents'
export const API_ROUTES_CHECK_BSO = '/api/v1/rgs/bso/check'
export const API_ROUTES_SEND_APPEAL = '/api/v2/rgs/appeal/request'
export const API_ROUTES_SEND_FEEDBACK = '/api/v2/rgs/appeal/request'

export const API_ROUTES_SEND_SPORT_INSURANCE_CASE =
  '/api/v1/rgs/form/insurance-case'
export const API_ROUTES_DOWNLOAD_AGENTS = '/xls/download/agents-brokers'
export const API_ROUTES_GET_APPEAL = '/api/v1/rgs/appeal/request-info'
export const API_ROUTES_SEND_DEBTOR = '/api/v1/rgs/debtor/send'
export const API_ROUTES_SEND_SMS = '/api/v1/rgs/sms/send'
export const API_ROUTES_SEND_CONFIRM_EMAIL_APPEAL =
  '/api/v1/rgs/appeal/email-confirmation'

// insurance
export const API_ROUTES_REGISTER_CLAIM = '/api/v1/rgs/claim/create'
// feedback agents
export const API_ROUTES_FEEDBACK_AGENTS =
  '/api/v1/rgs/form/preferential-insurance'
export const API_SEARCH_BOT_SEARCHING = '/api/v1/rgs/search/'
export const API_SEARCH_BOT_AUTOCOMPLETE = '/api/v1/rgs/search/helpers/'
export const API_SEARCH_NEXT_QUESTION = '/api/v1/rgs/search/question/'

export const GET_PHONE_VERIFICATION_CODE = '/api/v1/rgs/form/send-code'
export const SEND_PHONE_VERIFICATION_CODE = '/api/v1/rgs/form/verify-code'
export const GET_FEEDBACK_SELECT_LISTS = '/api/v1/rgs/form/customer-request'

export const API_ROUTES_SEND_TO_AGENT = '/api/v1/rgs/agents/send'

// ships policy
export const API_ROUTES_SHIP_POLICY_SEARCH = '/api/v1/rgs/ships/checkInsurance'
