import {
  onBeforeUnmount,
  onMounted,
  ref,
  useStore,
  computed,
  watch,
  nextTick,
} from '@nuxtjs/composition-api'

// @ts-ignore
import { debounce } from 'throttle-debounce'
export const useHeaderHeight = () => {
  const { getters } = useStore()
  const hideHeader = computed<boolean>(() => getters['settings/getHideHeader'])

  // Размер верхнего меню в px, по умолчанию 160px, чтобы небыло сильного скачка
  const headerHeight = ref(160)
  const headerNavHeight = ref(45)
  const calculateHeaderHeight = (): void => {
    const headerEl = document.body.querySelector('.root-header')

    if (headerEl) {
      headerHeight.value = headerEl.getBoundingClientRect().height
    }

    if (hideHeader.value) {
      headerHeight.value = 0
    }

    const headerNavEl = document.body.querySelector('.header-main')
    if (headerNavEl) {
      headerNavHeight.value = headerNavEl.getBoundingClientRect().height
    }
  }
  const debouncedHandlerResize = debounce(50, false, calculateHeaderHeight)

  watch(hideHeader, () => {
    nextTick(calculateHeaderHeight)
  })

  onMounted(() => {
    calculateHeaderHeight()
    window.addEventListener('resize', debouncedHandlerResize)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', debouncedHandlerResize)
  })

  return { headerHeight, headerNavHeight }
}
