export const state = () => ({
  userIp: null,
})
export const getters = {
  GET_USER_IP: (state) => state.userIp,
}
export const mutations = {
  SET_USER_IP: (state, payload: string) => {
    state.userIp = payload
  },
}
