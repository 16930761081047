import { Notification } from '../contracts/Cards'

export type NotificationState = {
  notificationList: Notification[]
}

export const state = (): NotificationState => ({
  notificationList: [],
})

export const getters = {
  getNotificationList: (state: NotificationState) => state.notificationList,
}

export const mutations = {
  SET_NOTIFICATION(state: NotificationState, notification: Notification) {
    state.notificationList.push(notification)
  },
  DELETE_NOTIFICATION(state: NotificationState, index: number) {
    const deletionNotification = state.notificationList[index]
    deletionNotification.visible = false
    state.notificationList.splice(index, 1, deletionNotification)

    const hiddenNotifications = state.notificationList.filter(
      (notification) => !notification.visible
    )
    if (hiddenNotifications.length === state.notificationList.length) {
      state.notificationList = []
    }
  },
}
