import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Context } from '@nuxt/types'
import { HTTP_REST } from '~/plugins/axios'

const request = async function <T = any>(
  context: Context,
  method: 'get' | 'post', // keyof AxiosInstance,
  path: string,
  params: any = {},
  config: AxiosRequestConfig = {}
): Promise<{ status: number | null } & T> {
  const axios = HTTP_REST(context)
  const _config = {
    withCredentials: false,
    ...config,
  }
  const methods = {
    get: () =>
      axios.get<T>(path, {
        params,
        ..._config,
      }),
    post: () =>
      axios.post<typeof params, AxiosResponse<T>>(path, params, {
        ..._config,
      }),
  }
  try {
    const { data = {}, status = null } = await methods[method]()

    return {
      ...(data as T),
      status,
    }
  } catch (e) {
    const error = e as AxiosError<any>

    if (error?.response?.data) {
      return { ...error.response.data, status: error.response.status }
    } else {
      // Если ошибка не связана с axios, то прокидывается дальше
      throw e
    }
  }
}

export default request
