















import { throttle } from 'throttle-debounce'
import { defineComponent, ref } from '@nuxtjs/composition-api'
import { mapMutations, mapState } from 'vuex'
import FooterSection from '@/views/FooterSection.vue'
import PopupBuyOnline from '@/components/PopupBuyOnline'
import HeaderSection from '@/views/HeaderSection.vue'

export default defineComponent({
  components: {
    FooterSection,
    HeaderSection,
    PopupBuyOnline,
  },
  setup() {
    const showButtonOnlineBuy = ref(false)
    return {
      showButtonOnlineBuy,
    }
  },
  computed: {
    ...mapState('settings', {
      themeClassName: (state) => `theme-${state.theme}`,
    }),
    throttledScrollHandler() {
      return throttle(50, false, this.scrollHandler.bind(this))
    },
  },
  mounted() {
    window.addEventListener('scroll', this.throttledScrollHandler.bind(this))
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.throttledScrollHandler.bind(this))
  },
  methods: {
    ...mapMutations({
      toggleButton: 'buttonAgent/TOGGLE_BUTTON',
    }),
    queryElement(selector: string): Element | null {
      return document.body.querySelector(selector)
    },
    scrollHandler() {
      if (process.browser) {
        const btnEL = this.queryElement('.button--agent')
        const footerSectionCopyright = this.queryElement('.footer-copyright')

        if (btnEL && footerSectionCopyright) {
          const footerBound = footerSectionCopyright.getBoundingClientRect()
          const footerCopyrightPosition = footerBound.top

          const byOnlineBound = btnEL.getBoundingClientRect()
          const btnBuyOnlinePosition = byOnlineBound.bottom

          const isToggleBtn = btnBuyOnlinePosition < footerCopyrightPosition

          this.toggleButton(isToggleBtn)
        }
      }
    },
  },
})
