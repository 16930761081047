













import { Vue, Component } from 'nuxt-property-decorator'
import {
  defineComponent,
  computed,
  onMounted,
  ref,
  useStore,
} from '@nuxtjs/composition-api'
import BlockText from '@/components/BlockText.vue'

@Component
class Cookie extends Vue {
  static options: any
}

export default defineComponent({
  name: 'Cookie',
  components: { BlockText },
  props: Cookie.options.props,
  setup() {
    const { getters } = useStore()
    const cookie = computed(() => getters['settings/cookie'])
    const { text, textButton } = cookie.value
    const isAccepted = ref(true)
    const acceptCookie = () => {
      isAccepted.value = true
      localStorage.setItem('cookie-accepted', `${isAccepted.value}`)
    }

    onMounted(() => {
      isAccepted.value = localStorage.getItem('cookie-accepted') === 'true'
    })

    return {
      isAccepted,
      acceptCookie,
      text,
      textButton,
    }
  },
})
