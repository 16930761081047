import Vue from 'vue'

import {
  Container,
  Section,
  Layout,
  Row,
  Col,
  Dummy,
  Picture,
  Popup,
} from '@/packages/'

Vue.component('FContainer', Container)
Vue.component('FSection', Section)
Vue.component('FLayout', Layout)
Vue.component('FRow', Row)
Vue.component('FCol', Col)
Vue.component('FPicture', Picture)
Vue.component('FPopup', Popup)
Vue.component('Dummy', Dummy)
