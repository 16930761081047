import { PopupReviewDetailState } from '../contracts/PopupReviewDetail'
import { CardReview as TypeReview } from '~/contracts/Cards'

export const state = (): PopupReviewDetailState => {
  return {
    showPopupDetail: false,
    openPopupDetail: false,
    alternate: false,
    review: {},
  }
}

export const getters = {
  getShowPopupDetail: (state: PopupReviewDetailState) => state.showPopupDetail,
  getOpenPopupDetail: (state: PopupReviewDetailState) => state.openPopupDetail,
  getReview: (state: PopupReviewDetailState) => state.review,
  getIsAlternateView: (state: PopupReviewDetailState) => state.alternate,
}

export const mutations = {
  SET_SHOW_POPUP_DETAIL(state: PopupReviewDetailState, payload: boolean) {
    state.showPopupDetail = payload
  },
  SET_OPEN_POPUP_DETAIL(state: PopupReviewDetailState, payload: boolean) {
    state.openPopupDetail = payload
  },
  SET_REVIEW(state: PopupReviewDetailState, payload: TypeReview) {
    state.review = payload
  },
  SET_IS_ALTERNATE_VIEW(state: PopupReviewDetailState, payload: boolean) {
    state.alternate = payload
  },
}
