import sanitizeHtml from 'sanitize-html'
export default (el, html, isSsr) => {
  const { value } = html

  if (!value) {
    el.innerHTML = null
  } else {
    const sanitized = sanitizeHtml(value, {
      allowedClasses: {
        '*': [
          'colored-title',
          'link--basic',
          'red-text',
          'desktop-only',
          'list-item',
          'section-big',
          'section-small',
          'table-wrapper',
          'arrow-right',
        ],
      },
      allowedTags: [
        'address',
        'article',
        'aside',
        'footer',
        'header',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'hgroup',
        'main',
        'nav',
        'section',
        'blockquote',
        'dd',
        'div',
        'dl',
        'dt',
        'figcaption',
        'figure',
        'hr',
        'li',
        'main',
        'ol',
        'p',
        'pre',
        'ul',
        'a',
        'abbr',
        'b',
        'bdi',
        'bdo',
        'br',
        'cite',
        'code',
        'data',
        'dfn',
        'em',
        'i',
        'kbd',
        'mark',
        'q',
        'rb',
        'rp',
        'rt',
        'rtc',
        'ruby',
        's',
        'samp',
        'small',
        'span',
        'strong',
        'sub',
        'sup',
        'time',
        'u',
        'var',
        'wbr',
        'caption',
        'col',
        'colgroup',
        'table',
        'tbody',
        'td',
        'tfoot',
        'th',
        'thead',
        'tr',
        'img',
      ],
    })
    if (isSsr && el.data) {
      el.data.domProps = {
        innerHTML: sanitized,
      }
    } else {
      el.innerHTML = sanitized
    }
  }
}
