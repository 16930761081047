













































import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { defineComponent } from '@nuxtjs/composition-api'
import Link from '@/components/Link.vue'

@Component
class ActionList extends Vue {
  @Prop({
    default: () => ({
      activated: {
        link: '',
        icon: '',
        value: '',
      },
      insuranceCase: {
        link: '',
        icon: '',
        value: '',
      },
    }),
  })
  readonly actionsHeader: Object = {}

  @Prop({ default: false }) readonly isShowActivated: Boolean = false
  static options: any
}

export default defineComponent({
  name: 'ActionList',
  components: { Link },
  props: ActionList.options.props,
  setup() {},
})
