






















































import {
  defineComponent,
  watch,
  ref,
  computed,
  useStore,
} from '@nuxtjs/composition-api'
import { CardImage } from '@/contracts/Cards'
import Link from '@/components/Link.vue'

import { defineProps } from '@/helpers/defineProps'
import { PictureSizes } from '@/contracts/PictureSizes'
import BlockButtons from '@/components/BlockButtons.vue'

export default defineComponent({
  name: 'CardImage',
  components: { BlockButtons, Link },
  props: defineProps<CardImage>({
    title: {
      default: '',
    },
    description: {
      default: '',
    },
    url: {
      default: '',
    },
    textLink: {
      default: '',
    },
    colImage: {
      default: 0,
    },
    mdImage: {
      default: 0,
    },
    smImage: {
      default: 0,
    },
    image: {
      default: () => ({ url: '' }),
    },
    imageRectangleFirst: {
      default: () => ({ url: '' }),
    },
    imageRectangleSecond: {
      default: () => ({ url: '' }),
    },
    hasBorder: {
      default: false,
    },
    hasCoverBg: {
      default: true,
    },
    hover: {
      default: '',
    },
    actionButtons: {
      default: () => [],
    },
    actionButtonsAlignment: {
      default: 'left',
    },
  }),
  setup(props) {
    const store = useStore()
    const currentMq = computed<string>(
      () => store.getters['mediaQuery/currentMq']
    )
    const deskUrl = ref<string>('')
    const mobUrl = ref<string>('')
    const sizesConst = {
      12: [980, 326],
      8: [853, 326],
      6: [653, 326],
      4: [326, 326],
      3: [326, 326],
      2: [326, 326],
    }
    const sizes = ref<PictureSizes>({
      sm: props.smImage === 1 ? [171, 171] : [343, 171],
      md: props.mdImage === 4 ? [490, 326] : [980, 326],
      lg: sizesConst[props.colImage] || [326, 326],
    })

    const cols = {
      12: props.imageRectangleSecond.url,
      8: props.imageRectangleFirst.url,
      6: props.imageRectangleFirst.url,
      4: props.image.url,
      3: props.image.url,
      2: props.image.url,
    }

    const getImageUrl = () => {
      deskUrl.value = cols[props.colImage]

      mobUrl.value =
        currentMq.value === 'tablet'
          ? cols[props.mdImage < 4 ? 4 : props.mdImage]
          : cols[props.smImage < 4 ? 4 : props.smImage]
    }

    watch(currentMq, getImageUrl)
    getImageUrl()
    const imageDesktop = computed<string>((): string => deskUrl.value)
    const imageMobile = computed<string>((): string => mobUrl.value)

    return {
      imageDesktop,
      imageMobile,
      sizes,
    }
  },
})
