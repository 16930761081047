const middleware = {}

middleware['oldPage'] = require('../middleware/oldPage.ts')
middleware['oldPage'] = middleware['oldPage'].default || middleware['oldPage']

middleware['setLang'] = require('../middleware/setLang.js')
middleware['setLang'] = middleware['setLang'].default || middleware['setLang']

middleware['userIp'] = require('../middleware/userIp.ts')
middleware['userIp'] = middleware['userIp'].default || middleware['userIp']

export default middleware
