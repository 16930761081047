<template>
  <div class="col" :class="colModifer">
    <slot />
  </div>
</template>

<script>
function colVaidator(value) {
  return typeof value === 'number' ? true : ['hide', 'auto'].includes(value)
}
export default {
  name: 'Col',
  props: {
    col: {
      type: [Number, String],
      default: 'auto',
      validator: colVaidator,
    },
    lg: {
      type: [Number, String],
      default: null,
      validator: colVaidator,
    },
    md: {
      type: [Number, String],
      default: null,
      validator: colVaidator,
    },
    sm: {
      type: [Number, String],
      default: null,
      validator: colVaidator,
    },
    isNoGrow: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Number,
      default: null,
    },
    aligment: {
      type: String,
      default: '',
      validator(value) {
        return ['start', 'end', 'center', 'baseline', 'stretch', ''].includes(
          value
        )
      },
    },
    justify: {
      type: String,
      default: 'start',
      validator(value) {
        return [
          'start',
          'end',
          'center',
          'space-between',
          'stretch',
          '',
        ].includes(value)
      },
    },
    alignItems: {
      type: String,
      default: '',
      validator(value) {
        return ['start', 'end', 'center', 'baseline', 'stretch', ''].includes(
          value
        )
      },
    },
  },
  computed: {
    colModifer() {
      let aligment = ''
      switch (this.aligment) {
        case 'end':
          aligment = 'as-fe '
          break
        case 'center':
          aligment = 'as-c '
          break
        case 'baseline':
          aligment = 'as-b '
          break
        case 'stretch':
          aligment = 'as-s '
          break
        case 'start':
          aligment = 'as-fs '
          break
        default:
          aligment = ''
      }
      const colsLg = this.lg ? `col-lg-${this.lg} ` : ''
      const colsMd = this.md ? `col-md-${this.md} ` : ''
      const colsSm = this.sm ? `col-sm-${this.sm} ` : ''
      const order = this.order ? `order-${this.order} ` : ''
      const cols = this.lg && this.md && this.sm ? '' : `col-${this.col} `
      const justify = this.justify ? `jc-${this.justify} ` : ''
      const alignItems = this.alignItems ? `ai-${this.alignItems} ` : ''

      const isNoGrow = this.isNoGrow ? 'no-grow ' : ''
      return `${cols}${colsLg}${colsMd}${colsSm}${aligment}${isNoGrow}${order}${justify}${alignItems}`
    },
  },
}
</script>

<style lang="scss" scoped>
$mobile: $MOBILE;
$mobile_tablet: $MOBILE_TABLET;
$tablet: $TABLET;
$tablet_desktop: $TABLET_DESKTOP;
$desktop: $DESKTOP;
$desktop_large: $DESKTOP_LARGE;
$containerWidthDesktop: $CONTAINER_WIDTH_DESKTOP + $COL_GAP_DESKTOP / 2;
$colCountDesktop: $COL_COUNT_DESKTOP;
$containerWidthTablet: $CONTAINER_WIDTH_TABLET;
$colCountTablet: $COL_COUNT_TABLE;
$containerWidthMobile: $CONTAINER_WIDTH_MOBILE;
$colCountMobile: $COL_COUNT_MOBILE;
$colGapDesktop: ($COL_GAP_DESKTOP / 2);
$colGapTablet: ($COL_GAP_TABLET / 2);
$colGapMobile: ($COL_GAP_MOBILE / 2);

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  @for $colNum from 1 through $colCountDesktop {
    &.col-#{$colNum} {
      position: relative;
      flex: 0 0 ($colNum / $colCountDesktop) * 100%;
      min-height: 1px;
      max-width: ($colNum / $colCountDesktop) * 100%;
    }
  }

  @for $colNum from 1 through 5 {
    &.order-#{$colNum} {
      order: $colNum;
    }
  }

  &.col-auto {
    flex: 1 1 (100% / $colCountDesktop);
    max-width: none;
    width: auto;
  }

  &.no-grow {
    flex-grow: 0 !important;
  }

  &.as-c {
    align-self: center;
  }

  &.as-s {
    align-self: stretch;
  }

  &.as-b {
    align-self: baseline;
  }

  &.as-fs {
    align-self: flex-start;
  }

  &.as-fe {
    align-self: flex-end;
  }

  &.jc-center {
    justify-content: center;
  }

  &.jc-stretch {
    justify-content: stretch;
  }

  &.jc-space-between {
    justify-content: space-between;
  }

  &.jc-start {
    justify-content: flex-start;
  }

  &.jc-end {
    justify-content: flex-end;
  }

  &.ai-center {
    align-items: center;
  }

  &.ai-stretch {
    align-items: stretch;
  }

  &.ai-baseline {
    align-items: baseline;
  }

  &.ai-start {
    align-items: flex-start;
  }

  &.ai-end {
    align-items: flex-end;
  }

  .no-gap & {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .is-debug {
    .row.no-gap & {
      box-shadow: inset 0 0 0 1px rgba(96, 125, 139, 0.4) !important;
    }
  }
}

@media #{$desktop} {
  .is-debug .col {
    box-shadow: inset 0 0 0 1px rgba(96, 125, 139, 0.4),
      inset $colGapDesktop 0 0 0 rgba(128, 203, 196, 0.2),
      inset -$colGapDesktop 0 0 0 rgba(128, 203, 196, 0.2);
  }

  .col {
    padding-right: $colGapDesktop;
    padding-left: $colGapDesktop;

    @for $colNum from 1 through $colCountDesktop {
      &.col-lg-#{$colNum} {
        flex: 0 0 floor(($colNum / $colCountDesktop) * 100%);
        max-width: floor(($colNum / $colCountDesktop) * 100%);
      }
    }

    &-lg-hide {
      display: none;
    }
  }
}

@media #{$tablet} {
  .is-debug .col {
    box-shadow: inset 0 0 0 1px rgba(96, 125, 139, 0.4),
      inset $colGapTablet 0 0 0 rgba(128, 203, 196, 0.2),
      inset -$colGapTablet 0 0 0 rgba(128, 203, 196, 0.2);
  }

  .col {
    padding-right: $colGapTablet;
    padding-left: $colGapTablet;

    @for $colNum from 1 through $colCountTablet {
      &.col-md-#{$colNum} {
        flex: 0 0 floor(($colNum / $colCountTablet) * 100%);
        max-width: floor(($colNum / $colCountTablet) * 100%);
      }
    }

    &-md-hide {
      display: none;
    }
  }
}

@media #{$mobile} {
  .is-debug .col {
    box-shadow: inset 0 0 0 1px rgba(96, 125, 139, 0.4),
      inset $colGapMobile 0 0 0 rgba(128, 203, 196, 0.2),
      inset -$colGapMobile 0 0 0 rgba(128, 203, 196, 0.2);
  }

  .col {
    padding-right: $colGapMobile;
    padding-left: $colGapMobile;

    @for $colNum from 1 through $colCountMobile {
      &.col-sm-#{$colNum} {
        flex: 0 0 floor(($colNum / $colCountMobile) * 100%);
        max-width: floor(($colNum / $colCountMobile) * 100%);
      }
    }

    &-sm-hide {
      display: none;
    }
  }
}
</style>
