



























import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { defineComponent, ref } from '@nuxtjs/composition-api'
import Link from '@/components/Link.vue'

@Component
class CategoryList extends Vue {
  @Prop() readonly list: ReadonlyArray<any> = []
  @Prop({ default: '' }) readonly pagePath: string | undefined
  @Prop({ default: true }) readonly isDisabledActiveTab: Boolean | undefined
  static options: any
}

export default defineComponent({
  name: 'CategoryList',
  components: { Link },
  props: CategoryList.options.props,
  setup(_props, { emit }: { emit: Function }) {
    const activeTab = ref(NaN)
    const onClick = (event: Event, index: number) => {
      activeTab.value = index
      emit('on-click', event.target, index)
    }
    return {
      onClick,
      activeTab,
    }
  },
})
