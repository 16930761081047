

















import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { computed, defineComponent, useStore } from '@nuxtjs/composition-api'
import Link from '@/components/Link.vue'
import { IHolidayTheme } from '@/contracts/Settings'

@Component
class MenuLogotype extends Vue {
  @Prop() readonly logotype: Object = {}
  static options: any
}
export default defineComponent({
  name: 'MenuLogotype',
  components: { Link },
  props: MenuLogotype.options.props,
  setup() {
    const store = useStore()

    const holidayTheme = computed<IHolidayTheme>(
      () => store.getters['settings/holidayTheme']
    )

    return {
      holidayTheme,
    }
  },
})
