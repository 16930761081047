export const state = () => {}

export const getters = {
  news: (state) => state.news,
  sectionData: (state) => state.sectionData,
}

export const mutations = {
  SET_NEWS(state, payload) {
    state.news = payload.newsAdapt
    state.sectionData = payload.sectionNewsAdapt
  },
}

export const actions = {
  init({ commit }: { commit: Function }, payload): void {
    commit('SET_NEWS', payload)
  },
}
