export const buttonsAction = () => {
  const scrollToForm = () => {
    const form = document.querySelector('.section-form-anchor')
    if (form) {
      window.scrollTo({
        top: form.getBoundingClientRect().top + window.pageYOffset,
        behavior: 'smooth',
      })
    }
  }

  const scrollToBuyByTelForm = () => {
    const form = document.querySelector('.buyByTel')
    if (form) {
      window.scrollTo({
        top: form.getBoundingClientRect().top + window.pageYOffset,
        behavior: 'smooth',
      })
    }
  }

  const scrollToCallbackRegion = () => {
    const form = document.querySelector('.callbackRegion')
    if (form) {
      window.scrollTo({
        top: form.getBoundingClientRect().top + window.pageYOffset,
        behavior: 'smooth',
      })
    }
  }

  const scrollToAgentsForm = () => {
    const form = document.querySelector('.agents')
    if (form) {
      window.scrollTo({
        top: form.getBoundingClientRect().top + window.pageYOffset,
        behavior: 'smooth',
      })
    }
  }

  const scrollToFrame = () => {
    const frame = document.querySelector('.section-frame-anchor')
    if (frame) {
      window.scrollTo({
        top: frame.getBoundingClientRect().top,
        behavior: 'smooth',
      })
    }
  }

  const scrollToContacts = () => {
    const frame = document.querySelector('.section-contacts-anchor')
    if (frame) {
      window.scrollTo({
        top: frame.getBoundingClientRect().top,
        behavior: 'smooth',
      })
    }
  }
  const handlers = {
    scrollToForm,
    scrollToContacts,
    scrollToFrame,
    scrollToBuyByTelForm,
    scrollToAgentsForm,
    scrollToCallbackRegion,
  }
  const clickHandler = (
    type: string,
    eventAction?: string | undefined,
    eventCategory?: string | undefined,
    jsCode?: string | undefined
  ) => {
    if (handlers[type]) {
      handlers[type]()
    }

    try {
      if (jsCode?.trim()) {
        eval(jsCode)
      }
    } catch (e) {
      console.error(e)
    }

    if (eventAction && eventCategory && window.dataLayer) {
      window.dataLayer.push({
        event: 'addEvents',
        eventCategory,
        eventAction,
        eventLabel: '',
        eventValue: '',
      })
    }
  }
  return { clickHandler }
}
