import { render, staticRenderFns } from "./BlockText.vue?vue&type=template&id=64b3d596&scoped=true&"
import script from "./BlockText.vue?vue&type=script&lang=ts&"
export * from "./BlockText.vue?vue&type=script&lang=ts&"
import style0 from "./BlockText.vue?vue&type=style&index=0&id=64b3d596&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64b3d596",
  null
  
)

export default component.exports