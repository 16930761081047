<template>
  <error :error="error" />
</template>
<script>
import { useStore } from '@nuxtjs/composition-api'
import Error from '@/views/Error'
export default {
  components: { Error },
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { commit } = useStore()
    commit('intendet/SET_INTENDET', false)
  },
}
</script>
