

















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { defineComponent } from '@nuxtjs/composition-api'

@Component
class SectionNotifs extends Vue {
  static options: any
  @Prop() readonly notif: Object = {}
}
const sectionNotifsProps = SectionNotifs.options.props

export default defineComponent({
  name: 'SectionNotifs',
  components: {},
  props: { ...sectionNotifsProps },
  setup() {
    return {}
  },
})
