
































































import {
  defineComponent,
  onMounted,
  ref,
  useContext,
  useStore,
} from '@nuxtjs/composition-api'
import { computed, nextTick } from '@vue/composition-api'
import Popup from '@/packages/basis/components/Popup.vue'
import SearchInput from '@/components/SearchPopup/SearchInput.vue'
import SearchAnswer from '@/components/SearchPopup/SearchAnswer.vue'
import SearchQuestion from '@/components/SearchPopup/SearchQuestion.vue'
import { Logger } from '@/composition/Logger'
import { DialogQuestion } from '@/store/popupSearchBot'
import SearchSuggestions from '@/components/SearchPopup/SearchSuggestions.vue'

export default defineComponent({
  name: 'SearchPopup',
  components: {
    SearchSuggestions,
    SearchQuestion,
    SearchAnswer,
    SearchInput,
    Popup,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const searchInputRef = ref<InstanceType<typeof SearchInput> | null>(null)

    const store = useStore()
    const ctx = useContext()

    const search = ref()
    const contentElement = ref<HTMLDivElement>()
    let debounceTimer: ReturnType<typeof setTimeout> | null = null

    const suggestionsArray = ref<string[]>([])

    const dialogList = computed(
      () => store.getters['popupSearchBot/getDialogList']
    )

    const hideSearchPopup = () => {
      emit('hide-popup')
      store.dispatch('metricsBot/sendMetrics')
    }

    const onSelectSuggestion = (suggestion: string) => {
      const trimmedSearch = search.value.trim()

      if (suggestion.toLowerCase().startsWith(trimmedSearch.toLowerCase())) {
        search.value = suggestion
      } else {
        search.value = trimmedSearch + ' ' + suggestion
      }

      onInput(search.value)
    }

    const onInput = (value: string) => {
      search.value = value

      if (debounceTimer) clearTimeout(debounceTimer)

      if (!search.value.trim()) {
        suggestionsArray.value = [] // Очистка списка подсказок при пустом вводе
        return
      }

      debounceTimer = setTimeout(async () => {
        if (search.value.trim()) {
          try {
            const suggestionsRes =
              await ctx.$api.requests.getSearchBotAutocomplete(search.value)

            if (suggestionsRes.status === 200) {
              suggestionsArray.value = Object.values(suggestionsRes).filter(
                (item): item is string => typeof item === 'string'
              )
            } else {
              suggestionsArray.value = []
            }
          } catch (error) {
            console.error('Ошибка получения подсказок:', error)
          }
        }
      }, 500)
    }

    const handleScroll = () => {
      nextTick(() => {
        contentElement.value?.scroll({
          top: contentElement.value.scrollHeight,
          behavior: 'smooth',
        })
      })
    }

    const onSubmit = async () => {
      if (!search.value) return

      store.commit('popupSearchBot/SET_DIALOG_ITEM', {
        type: 'question',
        title: search.value,
      })

      handleScroll()
      try {
        const searchValue = search.value
        const searchResponse = await ctx.$api.requests.getSearchBotData(
          search.value
        )

        const searchData = Object.entries(searchResponse)
          .filter(([key]) => key !== 'status')
          .map(([_, value]) => value)

        onInput('')
        suggestionsArray.value = []

        if (searchData.length === 1) {
          const questionResponse =
            await ctx.$api.requests.getSearchBotNextQuestion(+searchData[0].id)

          if (questionResponse?.answers?.nextQuestion?.length < 1) {
            store.commit('popupSearchBot/SET_DIALOG_ITEM', {
              type: 'answer',
              title: questionResponse.title,
              result: {
                important: {
                  title:
                    questionResponse.answers.title || questionResponse.title,
                  preview: questionResponse.answers.result,
                },
              },
            })

            store.commit('metricsBot/ADD_SEARCH_COUNTER')
            store.commit('metricsBot/ADD_SEARCH', {
              question: searchValue,
              answer:
                questionResponse.title + ';' + questionResponse.answers.result,
            })
          } else if (questionResponse?.answers?.nextQuestion?.length > 1) {
            store.commit('popupSearchBot/SET_DIALOG_ITEM', {
              type: 'answer',
              title: 'Вот, что мне удалось найти:',
              questions: searchData.map((quest) => ({
                id: quest.id,
                name: quest.title,
              })),
              links: [],
            })

            store.commit('metricsBot/ADD_SEARCH_COUNTER')
            store.commit('metricsBot/ADD_SEARCH', {
              question: searchValue,
              answer: searchData.map((quest) => ({
                кнопка: quest.title,
              })),
            })
          }
        } else if (
          searchData.length > 1 &&
          searchResponse.status !== 404 &&
          searchResponse.status !== 500
        ) {
          store.commit('popupSearchBot/SET_DIALOG_ITEM', {
            type: 'answer',
            title: 'Вот, что мне удалось найти:',
            questions: searchData.map((quest) => ({
              id: quest.id,
              name: quest.title,
            })),
            links: [],
          })

          store.commit('metricsBot/ADD_SEARCH_COUNTER')

          store.commit('metricsBot/ADD_SEARCH', {
            question: searchValue,
            answer: searchData.map((quest) => ({
              кнопка: quest.title,
            })),
          })
        } else {
          const notFoundList = []

          try {
            for await (const answer of searchResponse.elements) {
              const nextQuestionRes =
                await ctx.$api.requests.getSearchBotNextQuestion(answer)
              notFoundList.push(nextQuestionRes.answers.result)
            }
          } catch (error) {
            console.error(error)
          }

          store.commit('popupSearchBot/SET_NOT_FOUND_LIST', {
            typeList: searchResponse.settings,
            list: notFoundList,
          })

          const notFoundText = computed(
            () => store.getters['popupSearchBot/getNotFoundText']
          )

          store.commit('popupSearchBot/SET_DIALOG_ITEM', {
            type: 'answer',
            title:
              notFoundText.value ||
              'К сожалению, мне ничего не удалось найти :( Пожалуйста, переформулируйте вопрос, или оставьте обращение в форме обратной связи <a style="color: white; text-decoration: underline" href="/feedback">https://www.rgs.ru/feedback</a>',
          })

          store.commit('metricsBot/ADD_SEARCH_COUNTER')
          store.commit('metricsBot/ADD_NOT_FOUND', {
            question: searchValue,
            answer:
              notFoundText.value ||
              'К сожалению, мне ничего не удалось найти :( Пожалуйста, переформулируйте вопрос, или оставьте обращение в форме обратной связи <a style="color: white; text-decoration: underline" href="/feedback">https://www.rgs.ru/feedback</a>',
          })
        }
        search.value = ''
      } catch (e) {
        Logger.error(e)
      }
      handleScroll()
    }

    const onSelectQuestion = async (question: DialogQuestion) => {
      store.commit('popupSearchBot/SET_DIALOG_ITEM', {
        type: 'question',
        title: question.name,
      })

      handleScroll()

      onInput('')
      suggestionsArray.value = []

      if (question.id === '0') {
        store.commit('popupSearchBot/SET_DIALOG_ITEM', {
          type: 'answer',
          title: question.result.title,
          result: {
            ...question.result,
          },
        })

        store.commit('metricsBot/ADD_BTN_COUNTER')
        store.commit('metricsBot/ADD_BUTTON', {
          question: question.name,
          answer: {
            ...question.result,
          },
        })

        handleScroll()
        return
      }

      try {
        const searchResponse = await ctx.$api.requests.getSearchBotNextQuestion(
          +question.id
        )

        if (searchResponse.answers.nextQuestion.length < 1) {
          store.commit('popupSearchBot/SET_DIALOG_ITEM', {
            type: 'answer',
            title: '',
            result: {
              important: {
                title: searchResponse.answers.title || searchResponse.title,
                preview: searchResponse.answers.result,
              },
            },
          })

          store.commit('metricsBot/ADD_BTN_COUNTER')

          store.commit('metricsBot/ADD_BUTTON', {
            question: question.name,
            answer:
              searchResponse.answers.title +
              ';' +
              searchResponse.answers.result,
          })

          handleScroll()
          return
        } else if (searchResponse.answers.nextQuestion.length > 1) {
          const nextQuestionArr = []

          for await (const answer of searchResponse.answers.nextQuestion) {
            const nextQuestionRes =
              await ctx.$api.requests.getSearchBotNextQuestion(answer)
            nextQuestionArr.push(nextQuestionRes)
          }

          store.commit('popupSearchBot/SET_DIALOG_ITEM', {
            id: searchResponse.id,
            type: 'answer',
            title: 'Вот, что мне удалось найти:',
            questions: nextQuestionArr.map((quest) => ({
              id: quest.id,
              name: quest.title,
              result: quest.result,
            })),
          })

          store.commit('metricsBot/ADD_BTN_COUNTER')
          store.commit('metricsBot/ADD_BUTTON', {
            question: question.name,
            answer: nextQuestionArr.map((quest) => ({
              название: quest.title,
            })),
          })
        }

        handleScroll()
      } catch (e) {
        Logger.error(e)
      }
    }

    onMounted(() => {
      nextTick(() => {
        searchInputRef.value?.$el?.querySelector('input')?.focus()
      })
      handleScroll()
      setTimeout(() => {
        store.commit('popupSearchBot/CLEAR_DIALOG_HISTORY') // Очистка localStorage через час
      }, 1800000)
      store.commit('popupSearchBot/LOAD_DIALOG_HISTORY')
    })

    return {
      contentElement,
      search,
      dialogList,
      hideSearchPopup,
      onSubmit,
      onInput,
      onSelectQuestion,
      searchInputRef,
      handleScroll,
      suggestionsArray,
      onSelectSuggestion,
    }
  },
})
