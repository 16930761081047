import { PropType } from '@nuxtjs/composition-api'

type PropOptions<T> = {
  default?: T | (() => T)
  required?: boolean
  validator?: () => boolean
}

type VueProps<T> = {
  [key in keyof T]: {
    type: PropType<T[key]>
    required: true
  } & PropOptions<T[key]>
}

type SimpleProps<T> = {
  [key in keyof T]: {
    default?: T[key] | (() => T[key])
    required?: boolean
    validator?: () => boolean
    /**
     * @deprecated Не указываем
     type: PropType<T>
     */
  }
}

export const defineProps = <T extends object>(
  args: SimpleProps<T>
): VueProps<T> => args as VueProps<T>
