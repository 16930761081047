interface regionTS {
  region: null | string
}

export const state = (): regionTS => {
  return {
    region: null,
  }
}

export const getters = {
  getRegion: (state: regionTS) => state.region,
}

export const mutations = {
  SET_REGION(state: regionTS, text: string) {
    state.region = text
  },
}

export const actions = {
  setCurrentRgion({ commit }: { commit: Function }, text: string): void {
    commit('SET_REGION', text)
  },
}
