import { render, staticRenderFns } from "./BlockButtonsItem.vue?vue&type=template&id=2a9a9b8b&scoped=true&"
import script from "./BlockButtonsItem.vue?vue&type=script&lang=ts&"
export * from "./BlockButtonsItem.vue?vue&type=script&lang=ts&"
import style0 from "./BlockButtonsItem.vue?vue&type=style&index=0&id=2a9a9b8b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a9a9b8b",
  null
  
)

export default component.exports