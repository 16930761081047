


















































import {
  defineComponent,
  useStore,
  watch,
  computed,
  SetupContext,
} from '@nuxtjs/composition-api'
import { ThemeType } from '@/contracts/Settings'
import Link from '@/components/Link.vue'

export default defineComponent({
  name: 'MobileMenu',
  components: { Link },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Object,
      default: () => ({
        activated: {
          link: '',
        },
        insuranceCase: {
          link: '',
        },
      }),
    },
    support: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }: SetupContext) {
    const togglePopup = (): void => emit('toggle-popup')
    const store = useStore()
    const showCityPopup = () => store.commit('popupCityList/SET_SHOWN', true)
    const closeAllPopups = () => emit('close-all-popups')
    const breadcrumbs = computed<any[]>(
      () => store.state.breadcrumbs.breadcrumbs
    )

    watch(breadcrumbs, closeAllPopups, { deep: true })

    const { state, commit } = useStore()

    const onClickBurger = (): void => {
      emit('toggle-burger')
      commit('popupBuyOnline/SET_SHOW_BUTTON', props.isActive)
    }

    return {
      showCityPopup,
      togglePopup,
      onClickBurger,
      closeAllPopups,
      isVipTheme: computed(() => state.settings.theme === ThemeType.vip),
    }
  },
})
