










import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { defineComponent } from '@nuxtjs/composition-api'
import Footer from '@/components/Footer/index.vue'

@Component
class FooterSection extends Vue {
  static options: any
  @Prop() readonly firstSection: Boolean = true
  @Prop() readonly secondSection: Boolean = true
  @Prop() readonly copyrightSection: Boolean = true
}
export default defineComponent({
  name: 'FooterSection',
  components: {
    Footer,
  },
  props: FooterSection.options.props,
})
