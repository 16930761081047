
















import {
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref,
} from '@nuxtjs/composition-api'
import { Notification as NotificationType } from '@/contracts/Cards'

export default defineComponent({
  name: 'Notification',
  props: {
    notification: {
      type: Object as PropType<NotificationType>,
      required: true,
    },
    index: {
      type: [Number, String],
      required: true,
    },
  },
  setup(props, { emit }) {
    const timeoutId = ref<ReturnType<typeof setTimeout>>()

    const onClose = () => {
      clearTimeout(timeoutId.value)
      emit('close', props.index)
    }

    onMounted(() => {
      timeoutId.value = setTimeout(
        () => {
          emit('close', props.index)
        },
        props.notification.variant === 'error' ? 10000 : 5000
      )
    })

    onUnmounted(() => {
      clearTimeout(timeoutId.value)
    })

    return {
      onClose,
    }
  },
})
