
import {
  defineComponent,
  getCurrentInstance,
  computed,
  ref,
} from '@nuxtjs/composition-api'

export default defineComponent({
  components: {},
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    info: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const uid = computed(() => getCurrentInstance().uid)
    const text = computed({
      get: () => props.value,
      set: (value: string | null) => emit('input', value),
    })
    const onChangeText = (e) => {
      text.value = e.target.value
    }
    const focused = ref(null)
    const active = computed(() => focused.value || text.value)
    const clearText = (): void => {
      text.value = null
    }
    return {
      onChangeText,
      text,
      uid,
      focused,
      active,
      clearText,
    }
  },
})
