export enum Locale {
  NotSet = 'NotSet',
  Ru = 'ru',
  En = 'en',
}

export interface StateLocale {
  readonly locales: ReadonlyArray<string>
  currentLocale: Locale
}
