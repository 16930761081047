export default function (_to, _from, savedPosition) {
  return new Promise((resolve) => {
    const timeout = 1000
    setTimeout(() => {
      const hash = window.location.hash
      if (savedPosition && !hash.length > 0) {
        resolve(savedPosition)
      } else if (hash.length > 0) {
        const el = document.body.querySelector(hash)
        if (el) {
          window.scrollTo({
            top: el.getBoundingClientRect().top + window.pageYOffset,
            behavior: 'smooth',
          })
        }
      } else if (_to.query.answer || _from.path === _to.path) {
        return {}
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
        resolve({ x: 0, y: 0 })
      }
    }, timeout)
  })
}
