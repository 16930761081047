













import { Component, Prop, mixins } from 'nuxt-property-decorator'
import { defineComponent } from '@nuxtjs/composition-api'
import { SectionCore } from '@/mixins/sectionCore'
import SectionBasic from '@/components/SectionBasic.vue'
import BlockCardImageGrid from '@/components/CardLists/BlockCardImageGrid.vue'

@Component
class SectionCardImageGrid extends mixins(SectionCore) {
  @Prop() readonly list: ReadonlyArray<any> = []
}

export default defineComponent({
  name: 'SectionCardImageGrid',
  components: {
    SectionBasic,
    BlockCardImageGrid,
  },
  props: SectionCardImageGrid.options.props,
  setup() {},
})
