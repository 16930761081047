import { SearchBotQuestionAnswerResult } from '../contracts/api/QuerySearchBot/QuerySearchBot.Response'

export type DialogQuestion = {
  id: string
  name: string
  result?: SearchBotQuestionAnswerResult
}

export type DialogLink = {
  id: string
  url: string
  title: string
}

export type DialogItem = {
  id: string
  type: 'answer' | 'question'
  title: string
  questions?: DialogQuestion[]
  links?: DialogLink[]
  result?: SearchBotQuestionAnswerResult
}

export const state = () => {
  return {
    dialogList: [] as DialogItem[],
    isShow: false,
    notFoundList: [],
    notFoundText: '',
    counter: -1,
  }
}

export const getters = {
  getIsShown: (state) => state.isShow,
  getDialogList: (state) => state.dialogList,
  getNotFoundList: (state) => state.notFoundList,
  getNotFoundText: (state) => state.notFoundText,
  getCounter: (state) => state.counter,
}

export const mutations = {
  SET_IS_SHOWN(state, value: boolean) {
    state.isShow = value
  },
  SET_DIALOG_ITEM(state, item: DialogItem) {
    state.dialogList.push(item)
    localStorage.setItem('chatHistory', JSON.stringify(state.dialogList))
  },

  LOAD_DIALOG_HISTORY(state) {
    const savedHistory = localStorage.getItem('chatHistory')
    if (savedHistory) {
      state.dialogList = JSON.parse(savedHistory)
    }
  },
  CLEAR_DIALOG_HISTORY(state) {
    state.dialogList = []
    localStorage.removeItem('chatHistory')
  },
  // SET_NOT_FOUND_LIST(state, payload) {
  //   const { typeList, list } = payload
  //
  //   if (state.notFoundList.length < 1) {
  //     list.map((item) => state.notFoundList.push(item))
  //   }
  //   if (typeList === 'RAND') {
  //     state.notFoundText =
  //       state.notFoundList[Math.floor(Math.random() * list.length)]
  //   } else if (typeList === 'ROUND') {
  //     if (state.counter < list.length) {
  //       if (state.counter === 0) {
  //         state.notFoundText = state.notFoundList[state.counter]
  //       }
  //       state.counter++
  //     } else {
  //       state.counter = 0
  //     }
  //     state.notFoundText = state.notFoundList[state.counter]
  //   }
  // },
  SET_NOT_FOUND_LIST(state, { typeList, list }) {
    if (!state.notFoundList.length) {
      state.notFoundList.push(...list)
    }

    if (typeList === 'RAND') {
      state.notFoundText =
        state.notFoundList[Math.floor(Math.random() * list.length)]
    } else if (typeList === 'ROUND') {
      state.counter = (state.counter + 1) % list.length
      state.notFoundText = state.notFoundList[state.counter]
    }
  },

  RESET_NOT_FOUND_DATA(state) {
    state.counter = 0
    state.notFoundList = []
    state.notFoundText = ''
  },
}
