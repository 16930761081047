<template>
  <f-section class="section-header">
    <f-container>
      <n-link to="/" class="header-link" />
    </f-container>
  </f-section>
</template>

<script>
export default {
  name: 'ErrorHeader',
}
</script>

<style scoped lang="scss">
section.section.section-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-bottom: 0;
  padding-top: 3.2rem;
  @include mobile {
    padding-top: 1.6rem;
  }
}

.header-link {
  transition: opacity 0.25s ease;
  width: 15rem;
  height: 2rem;
  display: block;
  background: url('/logoWhite.png') center / 100% no-repeat;
  @include desktop {
    &:hover {
      opacity: 0.6;
      cursor: pointer;
    }
  }

  @include mobile {
    width: 11rem;
    height: 1.5rem;
  }
}
</style>
