













































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { defineComponent, SetupContext, computed } from '@vue/composition-api'
import { useHeaderHeight } from '@/composition/headerHeight'
import AccordeonList from '@/components/Header/AccordeonList.vue'
import ActionList from '@/components/Header/ActionList.vue'
import MenuRight from '@/components/Header/MenuRight.vue'
import PopupFade from '@/components/PopupFade.vue'

@Component
class SectionMobileNavigation extends Vue {
  @Prop({ default: false }) readonly activeMobileMenu: Boolean | undefined
  @Prop({ default: false }) readonly activeTabMenu: Boolean | undefined
  @Prop({ default: false }) readonly isMobileTablet: Boolean | undefined
  @Prop({ default: () => {} }) readonly data: Object | undefined
  @Prop({ default: false }) readonly isShowActivated: Boolean = false
  static options: any
}

const sectionMobileNavigationProps = SectionMobileNavigation.options.props

export default defineComponent({
  name: 'SectionMobileNavigation',
  components: {
    AccordeonList,
    ActionList,
    MenuRight,
    PopupFade,
  },
  props: { ...sectionMobileNavigationProps },
  setup(_props, ctx: SetupContext) {
    const { headerHeight } = useHeaderHeight()
    const popupStyles = computed(() => {
      return {
        top: `${headerHeight.value}px`,
        height: `calc(100vh - ${headerHeight.value}px)`,
      }
    })
    const togglePopup = () => {
      ctx.emit('toggle-popup')
    }
    const setActiveTab = (
      element: any,
      index: number,
      isMobileTablet: boolean
    ) => {
      ctx.emit('on-click', element, index, isMobileTablet)
    }
    const closeTabMenu = () => {
      ctx.emit('closeTab')
    }
    const startSearch = () => {
      ctx.emit('start-search')
    }
    const closeAllPopups = (url = '') => {
      ctx.emit('close-all-popups', url)
    }
    return {
      togglePopup,
      startSearch,
      setActiveTab,
      popupStyles,
      closeTabMenu,
      closeAllPopups,
    }
  },
})
