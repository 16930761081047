<template>
  <transition ref="transition" :name="getTransitionName" tag="div">
    <div v-if="isShown" class="popup" @keydown.esc="hide">
      <div
        class="background"
        :class="{ 'transparent-bg': transparentBackground }"
        @click.self.stop="hide"
      />
      <div
        ref="scroll"
        class="scroll-wrapper"
        :class="{ 'is-fullsize': isFullSize }"
        @click.self.stop="hide"
      >
        <div
          class="container"
          :class="{ 'is-fullsize': isFullSize, 'is-fullwidth': isFullWidth }"
        >
          <button v-if="showHide" class="hide" type="button" @click="hide">
            <slot name="popup-hide-icon">
              <div class="hide-icon" />
            </slot>
          </button>

          <div
            ref="contentScroll"
            class="content"
            :class="{ 'is-fullsize': isFullSize }"
          >
            <slot />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    isFullSize: {
      type: Boolean,
      default: false,
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    innerHideIcon: {
      type: Boolean,
      default: false,
    },
    dontLock: {
      type: Boolean,
      default: false,
    },
    targetElSelector: {
      type: String,
      default: '',
    },
    fromRightSide: {
      type: Boolean,
      default: false,
    },
    fromTopSide: {
      type: Boolean,
      default: false,
    },
    fromBottomSide: {
      type: Boolean,
      default: false,
    },
    showHide: {
      type: Boolean,
      default: true,
    },
    transparentBackground: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['breadcrumbs']),

    getTransitionName() {
      if (this.fromRightSide) return 'popup-from-right-side'
      if (this.fromTopSide) return 'popup-from-top-side'
      if (this.fromBottomSide) return 'popup-from-bottom-side'
      return 'popup-anim'
    },
    ...mapGetters({ scrollBarWidth: 'scrollBarWidth/getScrollBarWidth' }),
  },
  watch: {
    breadcrumbs: {
      deep: true,
      handler() {
        this.hide()
      },
    },
    isShown(status) {
      if (status) {
        document.body.addEventListener('keyup', this.initHideEvents, false)
        if (!this.dontLock) {
          this.scrollLock()
        }
      } else {
        localStorage.setItem('onboarding', 'true')
        document.body.removeEventListener('keyup', this.initHideEvents, false)
        if (!this.dontLock) {
          this.scrollUnlock()
        }
      }
    },
  },
  mounted() {
    if (!this.dontLock && this.isShown) {
      this.scrollLock()
    }
  },
  beforeDestroy() {
    if (!this.dontLock) {
      this.scrollUnlock()
    }
  },
  methods: {
    hide() {
      this.$emit('hide-popup')
    },
    initHideEvents(e) {
      if (e.keyCode === 27) {
        this.hide()
      }
    },
    scrollLock() {
      document.body.style.overflow = 'hidden'
    },
    scrollUnlock() {
      document.body.style.overflow = ''
    },
  },
}
</script>

<style lang="scss" scoped>
$mobile: $MOBILE;
$tablet: $TABLET;
$mobile_tablet: $MOBILE_TABLET;
$topIndex: $zLayerPopups;

$container_width_desktop: $CONTAINER_WIDTH_DESKTOP;
$container_side_offset_tablet: $CONTAINER_SIDE_OFFSET_TABLET;
$container_side_offset_mobile: $CONTAINER_SIDE_OFFSET_MOBILE;

.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $topIndex;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  margin: auto;
  opacity: 1;
  transform: translateY(0);

  > .background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: $POPUP_BG_COLOR;
    &.transparent-bg {
      background: transparent;
    }
  }

  .scroll-wrapper {
    position: relative;
    z-index: 2;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;

    @media #{$mobile} {
      display: block;
      -webkit-overflow-scrolling: touch;
    }

    &.is-fullsize {
      overflow: hidden;
    }
  }

  .container {
    position: relative;
    z-index: 2;
    margin: auto;
    max-width: 90%;
    width: fit-content;
    height: fit-content;

    /* pointer-events: auto; */
    &.is-fullwidth {
      max-width: 100%;
      width: 100%;
      height: 100%;

      .hide {
        top: 3rem;
        right: calc((100vw - #{$container_width_desktop}) / 2 - 1rem);
        width: 4.8rem;
        height: 4.8rem;
        padding: 1rem;

        @include mobile {
          top: 2.4rem;
          right: $container_side_offset_mobile;
          width: 2.8rem;
          height: 2.8rem;
          padding: 0.5rem;
        }

        @include tablet {
          right: $container_side_offset_tablet;
        }

        .hide-icon {
          color: $TEXT_PRIMARY;
        }
      }
    }
  }

  .content {
    padding: 1px;
    background: $HEADER_BG_FULL_SIZE;
    &.is-fullsize {
      height: 100%;
      overflow-y: auto;
      @media #{$mobile} {
        width: 100%;
        height: 100%;
      }
    }
  }

  .hide {
    z-index: 1;
    position: absolute;
    top: -7rem;
    right: 0;
    padding: 0;
    width: 5rem;
    height: 5rem;
    border-width: 0;
    background: none;
    transition: opacity 0.3s ease;

    &:focus {
      outline: 0;
      opacity: 0.8;
    }

    &:hover {
      .hide-icon {
        opacity: 0.6;
      }
    }

    &:active {
      opacity: 0.3;
    }
  }

  .hide-icon {
    display: block;
    padding: 0;
    width: 100%;
    height: 100%;
    color: $HEADER_BG_FULL_SIZE;
    transition: color 0.25s ease;
    cursor: pointer;
    transform: translateX(0.3em);

    &:after,
    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 3px;
      height: 100%;
      background: currentColor;
      content: '';

      @include mobile {
        width: 2px;
      }
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:before {
      transform: rotate(45deg);
    }
  }

  @media #{$mobile} {
    .container {
      margin: 7rem auto 3rem;
      max-width: 90%;

      &.is-fullsize {
        margin: 0;
        min-height: 100%;
        max-width: 100%;
        width: 100%;
      }
    }

    .hide {
      top: -5rem;
      z-index: 2;
      width: 3rem;
      height: 3rem;
    }

    .hide-icon {
      &::after,
      &::before {
        height: 100%;
      }
    }
  }

  &.popup-anim-leave-active {
    transition: opacity 0.15s ease-in-out;
  }

  &.popup-anim-enter-active {
    transition: opacity 0.35s ease-in-out;
  }

  &.popup-anim-enter,
  &.popup-anim-leave,
  &.popup-anim-leave-active {
    opacity: 0;
  }
}
</style>
