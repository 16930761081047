import { computed } from '@nuxtjs/composition-api'

export const useColoredTitle = (title: string) => {
  const coloredTitle = computed((): string => {
    return title
      ? title.replace(/\*([^*]+)\*/g, '<span class="colored-title">$1</span>')
      : ''
  })
  return {
    coloredTitle,
  }
}

export const getColorTitle = (title: string): string => {
  return title.replace(/\*([^*]+)\*/g, '<span class="colored-title">$1</span>')
}
