
import {
  ref,
  defineComponent,
  useFetch,
  computed,
  useStore,
} from '@nuxtjs/composition-api'
import axios from 'axios'
import _ from 'lodash'
import InputSelect from '@/components/new-design/form/InputSelect.vue'
export default defineComponent({
  components: { InputSelect },
  props: {},
  setup(_props, { emit }) {
    const { getters, commit } = useStore()
    const popupProperties = computed(
      () => getters['settings/getPopupCityListProperties']
    )
    const search = computed(() => getters['popupCityList/getSearchText'])
    const cityList = ref([])
    const { fetch, fetchState } = useFetch(async () => {
      const innerData = []
      let response = null
      try {
        response = await axios({
          method: 'POST',
          url: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Token aca0cc1c8cceccb7982e989375b565b3e5f8c41d',
            'X-Secret': 'bacec067567b328a14827651368931de42663472',
          },
          timeout: 1000,
          data: {
            count: 10,
            language: 'ru',
            query: search.value,
          },
        })
      } catch (e) {
        console.log(e)
      }
      // eslint-disable-next-line array-callback-return
      response?.data.suggestions.map((el): void => {
        if (el.data.qc_geo !== '5' && el.data.settlement_type !== 'промзона') {
          innerData.push({
            id: null,
            title: el.value,
            value: el.data.settlement || el.data.city,
          })
        }
      })
      cityList.value = innerData
    })
    const onSearch = _.debounce((value) => {
      commit('popupCityList/SET_SEARCH_TEXT', value)
      if (search.value?.length > 2) fetch()
    }, 300)
    const onSelectCity = (city) => {
      emit('select', city)
    }
    return {
      popupProperties,
      cityList,
      fetchState,
      search,
      onSearch,
      onSelectCity,
    }
  },
})
