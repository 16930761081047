






import { computed, defineComponent } from '@nuxtjs/composition-api'
export default defineComponent({
  name: 'Section',
  props: {
    offset: {
      type: Object,
      default: () => ({
        desktop: null,
        mobile: null,
      }),
    },
  },
  setup(props) {
    const hasCustomOffset = computed(
      () => props.offset.mobile || props.offset.desktop
    )
    const sectionStyles = computed(() => ({
      '--custom-vertical-offset-mobile-top': props.offset.mobile
        ? props.offset.mobile[0] + 'px'
        : 'inherit',
      '--custom-vertical-offset-mobile-bottom': props.offset.mobile
        ? props.offset.mobile[1] + 'px'
        : 'inherit',
      '--custom-vertical-offset-desktop-top': props.offset.desktop
        ? props.offset.desktop[0] + 'px'
        : 'inherit',
      '--custom-vertical-offset-desktop-bottom': props.offset.desktop
        ? props.offset.desktop[1] + 'px'
        : 'inherit',
    }))
    return {
      sectionStyles,
      hasCustomOffset,
    }
  },
})
