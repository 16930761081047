









































































import Bowser from 'bowser'
import { debounce } from 'throttle-debounce'
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  useRouter,
  useStore,
  useContext,
  ref,
  computed,
  useRoute,
} from '@nuxtjs/composition-api'
import LayoutBlocks from '@/components/LayoutBlocks.vue'

import { yaCounter } from '@/composition/ym'
import {
  Debug as FDebug,
  mediaQueryHandler,
  scrollBarWidthHandler,
  userAgentHandler,
} from '@/packages/basis'
import {
  IHolidayTheme,
  Notification,
  SiteNotification,
} from '@/contracts/Settings'

const browsers = {
  safari: {
    desktop: 13,
    mobile: 12.4,
    tablet: 11,
  },
  opera: {
    desktop: 72,
    mobile: 3,
    tablet: 3,
  },
  chrome: {
    desktop: 87,
    mobile: 87,
    tablet: 87,
  },
  firefox: {
    desktop: 84,
    mobile: 38,
    tablet: 38,
  },
  edge: {
    desktop: 87,
    mobile: 87,
    tablet: 87,
  },
  'microsoft edge': {
    desktop: 87,
    mobile: 87,
    tablet: 87,
  },
  'samsung internet for android': {
    mobile: 13,
    tablet: 8,
  },
  'yandex browser': {
    desktop: 20,
    mobile: 20,
    tablet: 20,
  },
}

export default defineComponent({
  name: 'Layout',
  components: {
    BlockSiteNotification: () =>
      import('@/components/BlockSiteNotification.vue'),
    LayoutBlocks,
    BlockWorksNotify: () => import('@/components/BlockWorksNotify.vue'),
    FDebug,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const { $config } = useContext()

    const siteNotificationAccept = ref(
      process.browser
        ? localStorage.getItem('site-notification-accept') === 'true'
        : false
    )
    const notificationAccept = ref(
      process.browser
        ? localStorage.getItem(
            `notify-works-accepted${route.value.path?.replace(/\//g, '-')}`
          ) === 'true'
        : false
    )

    const hideFooter = computed(() => store.getters['settings/getHideFooter'])
    const hideHeader = computed(() => store.getters['settings/getHideHeader'])
    const indented = computed(() => store.getters['intendet/GET_INTENDET'])
    const notification = computed<Notification>(
      () => store.getters['settings/notification']
    )
    const allSiteNotification = computed<SiteNotification>(
      () => store.getters['settings/allSiteNotification']
    )
    const holidayTheme = computed<IHolidayTheme>(
      () => store.getters['settings/holidayTheme']
    )
    const getActive = (activeFrom: string, activeTo: string) => {
      const planDateOn = activeFrom && new Date(activeFrom)
      const planDateOff = activeTo && new Date(activeTo)
      const today = new Date()

      return planDateOn < today && today < planDateOff
    }
    const notificationDateIsActive = computed(() =>
      getActive(
        notification.value.notificationStartTime,
        notification.value.notificationEndTime
      )
    )
    const siteNotificationDateIsActive = computed(() =>
      getActive(
        allSiteNotification.value.notificationStartTime,
        allSiteNotification.value.notificationEndTime
      )
    )

    onMounted(() => {
      if (process.browser) {
        window.addEventListener(
          'resize',
          debounce(50, () => {
            mediaQueryHandler(store)
          }),
          false
        )
        mediaQueryHandler(store)
        scrollBarWidthHandler(store)
        userAgentHandler(store)
      }
      const showPopup = sessionStorage.getItem('hideOldBrowser') !== 'true'
      const {
        parsedResult: {
          browser: { name: _name = '', version: _version = '' } = {},
          platform: { type: _type = '' } = {},
        } = {},
      } = Bowser.getParser(window.navigator.userAgent)
      const type = _type.toLowerCase()
      const name = _name.toLowerCase()
      const version = _version.split('.')[0]

      const isUnSupported =
        !type ||
        !name ||
        !version ||
        !browsers[name] ||
        !browsers[name][type] ||
        version < browsers[name][type]

      if (showPopup && isUnSupported && !hideFooter && !hideHeader) {
        yaCounter('browser', $config.GTM)
        const router = useRouter()
        router.push({
          query: {
            toast: store.getters['settings/oldBrowserTitle'],
            description: store.getters['settings/oldBrowserDescription'],
            linkTitle: store.getters['settings/oldBrowserLinkTitle'],
            linkUrl: store.getters['settings/oldBrowserLinkUrl'],
            isBrowserPopup: 'true',
          },
        })
      }
    })
    onBeforeUnmount(() =>
      window.removeEventListener('resize', mediaQueryHandler, false)
    )
    const showDebug = $config.APP_ENV !== 'production'

    const closeSiteNotificationHandle = () => {
      siteNotificationAccept.value = true
    }
    const closeNotificationHandle = () => {
      notificationAccept.value = true
    }

    return {
      showDebug,
      indented,
      hideFooter,
      hideHeader,
      notification,
      allSiteNotification,
      notificationDateIsActive,
      siteNotificationDateIsActive,
      holidayTheme,
      siteNotificationAccept,
      notificationAccept,
      closeSiteNotificationHandle,
      closeNotificationHandle,
    }
  },
})
