import Vue from 'vue'
import { defineNuxtPlugin } from '@nuxtjs/composition-api'

export default defineNuxtPlugin(() => {
  Vue.directive('html-with-scripts', {
    bind(el, binding) {
      processHtml(el, binding.value)
    },
    update(el, binding) {
      if (binding.value !== binding.oldValue) {
        processHtml(el, binding.value)
      }
    },
  })
})

function processHtml(el, html) {
  el.innerHTML = html
  const scripts = el.querySelectorAll('script')
  scripts.forEach((script) => {
    const newScript = document.createElement('script')
    if (script.src) {
      newScript.src = script.src
      newScript.async = false
    } else {
      newScript.textContent = script.textContent
    }
    document.body.appendChild(newScript)
    script.remove()
  })
}
