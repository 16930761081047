import { render, staticRenderFns } from "./PopupCityList.vue?vue&type=template&id=210a66d1&scoped=true&"
import script from "./PopupCityList.vue?vue&type=script&lang=ts&"
export * from "./PopupCityList.vue?vue&type=script&lang=ts&"
import style0 from "./PopupCityList.vue?vue&type=style&index=0&id=210a66d1&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "210a66d1",
  null
  
)

export default component.exports