


























import {
  computed,
  defineComponent,
  useStore,
  SetupContext,
} from '@nuxtjs/composition-api'
import PopupFade from '@/components/PopupFade.vue'

export default defineComponent({
  name: 'PopupOldPage',
  components: { PopupFade },
  setup(_props, ctx: SetupContext) {
    const { commit } = useStore()
    const showPopup = computed(
      () => ctx.root.$store.getters['oldPage/GET_SHOW_POPUP']
    )
    const isMobileMq = computed(
      () => ctx.root.$store.getters['mediaQuery/isMobileMq']
    )
    const data = computed(
      () => ctx.root.$store.getters['oldPage/GET_DATA_POPUP']
    )
    const togglePopup = () => {
      commit('oldPage/TOGGLE_POPUP', false)
      if (data.value.isBrowserPopup) {
        sessionStorage.setItem('hideOldBrowser', 'true')
      }
    }
    const closePopup = (event: Event) => {
      const { target } = event || {}
      if (target && !target.closest('.popup-old-page-scroll-container')) {
        togglePopup()
      }
    }
    return {
      showPopup,
      togglePopup,
      isMobileMq,
      data,
      closePopup,
    }
  },
})
