
import { computed, defineComponent, ref, watch } from "@nuxtjs/composition-api";
import InputText from '@/components/new-design/form/basic/input-text/InputText.vue'
import SelectList from '@/components/new-design/form/basic/list/SelectList.vue'
export default defineComponent({
  components: { SelectList, InputText },
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    info: {
      type: String,
      default: '',
    },
    keyName: {
      type: String,
      default: 'id',
    },
    pending: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const searchText = ref(null)
    const isDropdownShown = ref(false)
    const error = ref(false)
    const accept = (value) => {
      emit('select', value)
    }
    const pending = computed(() => props.pending)
    watch(searchText, () => {
      isDropdownShown.value = searchText.value?.length > 2
      emit('input', searchText.value)
    })
    watch(pending, () => {
      error.value =
        !props.pending && searchText.value?.length > 3 && !props.options?.length
    })
    return {
      error,
      accept,
      isDropdownShown,
      searchText,
    }
  },
})
