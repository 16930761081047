

















import { defineComponent, useStore } from '@nuxtjs/composition-api'
import { computed } from '@vue/composition-api'
import Notification from '@/components/new-design/Notification.vue'

export default defineComponent({
  name: 'Notifications',
  components: { Notification },
  setup() {
    const store = useStore()

    const notificationList = computed(
      () => store.getters['notifications/getNotificationList']
    )

    const onClose = (index: number | string) => {
      store.commit('notifications/DELETE_NOTIFICATION', index)
    }

    return {
      notificationList,
      onClose,
    }
  },
})
