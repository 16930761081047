export const state = (): any => {
  return {
    showButton: false,
    showPopup: false,
    showFaqPopup: false,
  }
}

export const getters = {
  getShowButton: (state: any) => state.showButton,
  getShowPopup: (state: any) => state.showPopup,
  getShowFaqPopup: (state: any) => state.showFaqPopup,
}

export const mutations = {
  SET_SHOW_BUTTON(state: any, params: boolean) {
    state.showButton = params
  },
  SET_SHOW_POPUP(state: any, params: boolean) {
    state.showPopup = params
  },
  TOGGLE_FAQ_POPUP(state: any) {
    state.showFaqPopup = !state.showFaqPopup
  },
  SET_SHOW_FAQ_POPUP(state: any, params: boolean) {
    state.showFaqPopup = params
  },
}
