export default (ctx) => {
  return {
    httpEndpoint: ctx.$config.BACKEND_PUBLIC_URL,
    defaultHttpLink: false,
    fetchPolicy: 'network-only',
    httpLinkOptions: {
      uri:
        (process.browser
          ? ctx.$config.BACKEND_PUBLIC_URL
          : ctx.$config.BACKEND_PRIVATE_URL) + ctx.$config.GQ_API_PATH,
      withCredentials: true,
    },
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  }
}
