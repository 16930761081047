import { StateCookies } from '../contracts/StateCookies'

export const state = (): StateCookies => {
  return {
    userCookieText: '',
    userCookieIsOpen: true,
    userCookieAccepted: false,
  }
}

export const getters = {
  getUserCookieIsOpen: (state: StateCookies) => state.userCookieIsOpen,
  getUserCookieText: (state: StateCookies) => state.userCookieText,
}

export const mutations = {
  SET_USER_COOKIE_TEXT(state: StateCookies, text: string) {
    state.userCookieText = text
  },
  REACT_TO_COOKIES(state: StateCookies, value: boolean) {
    state.userCookieIsOpen = false
    state.userCookieAccepted = value
  },
}

export const actions = {
  setUserCookieContent({ commit }: { commit: Function }, text: string): void {
    commit('SET_USER_COOKIE_TEXT', text)
  },
  reactToCookies({ commit }: { commit: Function }, value: boolean): void {
    commit('REACT_TO_COOKIES', value)
  },
}
