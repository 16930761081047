
import {
  computed,
  defineComponent,
  watch,
  useStore,
} from '@nuxtjs/composition-api'
import BlockListSeoCities from '@/components/BlockListSeoCities.vue'
import SelectCityForm from '@/components/new-design/SelectCityForm.vue'

export default defineComponent({
  components: { SelectCityForm, BlockListSeoCities },
  props: {},
  setup(_props) {
    const { commit, getters } = useStore()
    const isShown = computed(() => getters['popupCityList/getShownStatus'])
    const closePopup = () => {
      commit('popupCityList/SET_SHOWN', false)
    }
    const onSelectCity = (city) => {
      document.cookie = `selectedCity=${city}; max-age=${60 * 60 * 24 * 30}`
      commit('popupCityList/SET_CITY_NAME', city)
      commit('popupCityList/SET_SHOWN', false)
    }
    watch(isShown, () => {
      if (isShown.value) {
        document.querySelector('body').style.overflow = 'hidden'
      } else {
        document.querySelector('body').style.overflow = null
      }
    })
    return {
      onSelectCity,
      closePopup,
      isShown,
    }
  },
})
