import { render, staticRenderFns } from "./PopupOldPage.vue?vue&type=template&id=2419dcf4&scoped=true&"
import script from "./PopupOldPage.vue?vue&type=script&lang=ts&"
export * from "./PopupOldPage.vue?vue&type=script&lang=ts&"
import style0 from "./PopupOldPage.vue?vue&type=style&index=0&id=2419dcf4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2419dcf4",
  null
  
)

export default component.exports