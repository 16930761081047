
















import { computed, defineComponent, useStore } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'PopupFade',
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    isNoPadding: {
      type: Boolean,
      default: false,
    },
    isFullSize: {
      type: Boolean,
      default: false,
    },
    fromBottomSide: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const store = useStore()
    const onClose = () => {
      emit('hide-popup')
    }

    const isMobileMq = computed<boolean>(
      () => store.getters['mediaQuery/isMobileMq']
    )

    return {
      onClose,
      isMobileMq,
    }
  },
})
