

































import {
  defineComponent,
  PropType,
  ref,
  useRoute,
} from '@nuxtjs/composition-api'
import Popup from '@/packages/basis/components/Popup.vue'
import Teleport from '@/components/Teleport'
import { buttonsAction } from '@/composition/buttons'
import { ButtonAction } from '@/contracts/ButtonHeroAction'
import { useNuxtLink } from '@/composition/nuxtLink'

export default defineComponent({
  name: 'BlockButtonsItem',
  components: { Popup, Teleport },
  props: {
    buttonItem: {
      type: Object as PropType<ButtonAction>,
      required: true,
    },
  },
  setup(_, { emit }) {
    const route = useRoute()
    const { clickHandler } = buttonsAction()
    const modalOpen = ref(false)

    const getStyles = (button: ButtonAction) => {
      if (button.color) return null
      return {
        '--btn-background': button.background,
        '--btn-background-hover': button.backgroundHover,
        '--btn-border-color': button.borderColor,
        '--btn-text-color': button.labelColor,
        '--btn-text-hover-color': button.labelHoverColor,
        '--btn-border-radius': button.borderRadius,
      }
    }

    const getPopupStyles = (button: ButtonAction) => {
      if (button.type !== 'openModal') return null
      return {
        '--popup-background': button.modalBackground || 'white',
        '--popup-border': button.modalBorder || '1px solid white',
        '--popup-radius': button.modalRadius || '8px',
      }
    }

    const getClasses = (button: ButtonAction) => {
      return {
        'arrow-left': button.arrow,
        'action-item__no-padding': !button.padding && !button.color,
        'action-item__custom-styles':
          button.labelHoverColor ||
          button.labelColor ||
          button.background ||
          button.backgroundHover,
        'action-item__border': button.borderColor,
        'btn--basic':
          (button.color === 'Красная' && button.type !== 'switchLink') ||
          (button.type === 'switchLink' && route.value.fullPath === button.url),
        'btn--second':
          (button.color === 'Белая' && button.type !== 'switchLink') ||
          (button.type === 'switchLink' && route.value.fullPath !== button.url),
      }
    }
    const componentProps = (item) => {
      const { isNuxtLink } = useNuxtLink(item.url)

      if (!item.url) {
        return { type: 'button' }
      }

      if (!isNuxtLink.value) {
        return {
          is: 'a',
          href: item.url,
        }
      }

      if (item.type === 'link' || item.type === 'switchLink')
        return { to: item.url }
      return { type: 'button' }
    }
    const onClick = (button: ButtonAction) => {
      if (button.type === 'openModal') {
        modalOpen.value = true
      }
      clickHandler(
        button.type,
        button.eventAction,
        button.eventCategory,
        button.jsCode
      )
      emit('button-click', button)
    }

    const onClosePopup = () => {
      modalOpen.value = false
    }

    return {
      modalOpen,
      componentProps,
      getPopupStyles,
      getStyles,
      onClosePopup,
      onClick,
      getClasses,
    }
  },
})
