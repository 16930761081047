import { computed } from '@nuxtjs/composition-api'

export const useNuxtLink = (url: string) => {
  const isNuxtLink = computed((): boolean => {
    return !!url && !(url.startsWith('http') || url.startsWith('https'))
  })
  return {
    isNuxtLink,
  }
}
