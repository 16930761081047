import { MqKey, StateMq, MqValue, MqPayload } from '../contracts/StateMq'

export const state = (): StateMq => {
  return {
    value: MqValue.Desktop,
    mqKey: MqKey.Sm,
    list: [
      {
        key: MqKey.Sm,
        value: 0,
      },
      {
        key: MqKey.Md,
        value: 768,
      },
      {
        key: MqKey.Lg,
        value: 1024,
      },
    ],
  }
}

export const getters = {
  currentMq: (state: StateMq): MqValue => state.value,
  isDesktopMq: (state: StateMq): boolean => state.value === MqValue.Desktop,
  isMobileMq: (state: StateMq): boolean => state.value === MqValue.Mobile,
}

export const mutations = {
  UPDATE_VALUE(state: StateMq, payload: MqPayload) {
    state.value = payload.currentMediaName
    state.mqKey = payload.currentMediaKey
  },
}

export const actions = {
  update({ commit }: { commit: Function }, mediaQuery: MqPayload) {
    commit('UPDATE_VALUE', mediaQuery)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
