interface MenuTree {
  menuTree: []
}

export const state = () => {}

export const getters = {
  getData: (state: MenuTree) => state.menuTree,
}

export const mutations = {
  SET_DATA(state: MenuTree, payload: []) {
    state.menuTree = payload
  },
}

export const actions = {
  setData({ commit }: { commit: Function }, payload: []): void {
    commit('SET_DATA', payload)
  },
}
