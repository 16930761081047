


































import { computed, defineComponent, useStore } from '@nuxtjs/composition-api'
import SectionCardImageGrid from '../views/SectionCardImageGrid.vue'
import Popup from '@/packages/basis/components/Popup.vue'

export default defineComponent({
  name: 'PopupBuyOnline',
  components: { SectionCardImageGrid, Popup },
  props: {
    textData: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { commit, state, getters } = useStore()

    const showPopup = computed(() => state.popupBuyOnline.showPopup)
    const {
      value: { list, title },
    } = computed(() => getters['settings/popupBuyOnline'])
    const togglePopup = () => commit('popupBuyOnline/SET_SHOW_POPUP', false)
    const onClick = (event: Event) => {
      const { target }: { target: EventTarget | null } = event
      if (target && target.closest('a')) {
        commit('popupBuyOnline/SET_SHOW_POPUP', false)
      }
    }
    return {
      showPopup,
      list,
      title,
      togglePopup,
      onClick,
    }
  },
})
