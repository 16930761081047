




import {
  computed,
  defineComponent,
  onMounted,
  useStore,
} from '@nuxtjs/composition-api'
export default defineComponent({
  setup(_props) {
    const store = useStore()
    const city = computed(() => {
      return store.getters['popupCityList/getCity']?.length > 16
        ? store.getters['popupCityList/getCity'].substring(0, 16) + '...'
        : store.getters['popupCityList/getCity']
    })
    onMounted(() => {
      const cookieCity = document.cookie
        .split(';')
        .find((item) => item.includes('selectedCity'))
        ?.split('=')[1]
      if (cookieCity) store.commit('popupCityList/SET_CITY_NAME', cookieCity)
    })
    return {
      city,
    }
  },
})
