export const state = () => {
  return {
    showPopup: false,
    data: {
      toats: '',
      description: '',
      linkTitle: '',
      linkUrl: '',
      isBrowserPopup: '',
    },
  }
}
export const getters = {
  GET_SHOW_POPUP(state) {
    return state.showPopup
  },
  GET_DATA_POPUP(state) {
    return state.data
  },
}

export const mutations = {
  TOGGLE_POPUP(state, param) {
    state.showPopup = param
  },
  SET_DATA_POPUP(state, param) {
    state.data = param
  },
}
