type intendet = {
  indented: boolean
}

export const state = () => ({
  indented: true,
})

export const mutations = {
  SET_INTENDET: (state: intendet, payload: boolean) => {
    state.indented = payload
  },
}

export const getters = {
  GET_INTENDET: (state: intendet) => state.indented,
}
