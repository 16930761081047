export const state = (): any => {
  return {
    text: null,
  }
}

export const getters = {
  getText: (state: any) => state.text,
  isActive: (state: any) => !!state.text,
}

export const mutations = {
  SET_TEXT(state: any, params: string | null) {
    state.text = params
  },
}
