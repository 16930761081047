import { dataPopupOldPage } from '@/contracts/oldPage'
export default function (ctx) {
  const { route, store, redirect, from } = ctx
  const query = route.query
  if (query.toast) {
    const {
      toast = '',
      description = '',
      linkTitle = '',
      linkUrl = '',
      isBrowserPopup = null,
    } = query
    const data: dataPopupOldPage = {
      toast,
      description,
      linkTitle,
      linkUrl,
      isBrowserPopup,
    }
    setTimeout(() => {
      store.commit('oldPage/SET_DATA_POPUP', data)
      store.commit('oldPage/TOGGLE_POPUP', true)
    }, 0)

    from ? redirect(from.path) : redirect(linkUrl)
  }
}
