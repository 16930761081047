












import {
  defineComponent,
  onMounted,
  computed,
  nextTick,
  useRouter,
  useStore,
  ref,
  PropType,
} from '@nuxtjs/composition-api'
import { debounce } from 'throttle-debounce'
import { yaReach } from '@/composition/ym'

export default defineComponent({
  name: 'BlockText',
  props: {
    textData: {
      type: String,
      default: '',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    showFullTable: {
      type: String as PropType<'Да' | 'Нет' | ''>,
      default: '',
    },
    alignment: {
      type: String as PropType<'left' | 'right'>,
      default: 'left',
    },
  },
  setup(props) {
    const store = useStore()
    const openText = computed(() => store.getters['settings/btnShowMore'])
    const closeText = computed(() => store.getters['settings/btnRollUp'])
    const router = useRouter()
    const isNuxtLink = /^(http|ftp|tel:|mailto:)/
    const blockText = ref<HTMLDivElement | null>()

    onMounted(() => {
      nextTick(() => {
        blockText.value?.querySelectorAll('a').forEach((link) => {
          const href = link.getAttribute('href')
          if (!isNuxtLink.test(href)) {
            link.onclick = (e) => {
              if (link.dataset.metrika) yaReach(link.dataset.metrika)
              e.preventDefault()
              router.push(href)
            }
          } else {
            link.onclick = () => {
              if (link.dataset.metrika) yaReach(link.dataset.metrika)
            }
          }
        })

        blockText.value
          ?.querySelectorAll('.table-wrapper')
          .forEach((wrapper) => {
            const table = wrapper.querySelector('table')
            const fullTableAttribute = table.getAttribute('full-table')
            const countRows = table.querySelectorAll('tr')
            if (
              props.showFullTable !== 'Да' &&
              !fullTableAttribute &&
              countRows.length > 5
            ) {
              const resizeHandler = (isResize: boolean = false) => {
                const clientHeight: number = table.scrollHeight
                let previewHeight: number = 0
                let index: number = 0
                while (index < 5) {
                  previewHeight += countRows[index].scrollHeight
                  index++
                }
                if (!Array.from(wrapper.classList).includes('opened')) {
                  wrapper.style.cssText = `height: ${previewHeight}px;`
                } else {
                  wrapper.style.cssText = `height: ${clientHeight}px;`
                }
                const onClickBtnHandler = (btnEl): void => {
                  const opened: boolean = Array.from(
                    wrapper.classList
                  ).includes('opened')
                  if (opened) {
                    wrapper.style.cssText = `height: ${previewHeight}px;`
                    btnEl.classList.remove('opened')
                    wrapper.classList.remove('opened')
                    btnEl.innerHTML = openText.value
                  } else {
                    wrapper.style.cssText = `height: ${clientHeight}px;`
                    wrapper.classList.add('opened')
                    btnEl.classList.add('opened')
                    btnEl.innerHTML = closeText.value
                  }
                }

                if (!isResize) {
                  const button = document.createElement('button')
                  button.innerHTML = openText.value
                  button.classList.add('btn--show-more')
                  button.onclick = (): void => onClickBtnHandler(button)
                  wrapper.after(button)
                } else {
                  const button = wrapper.nextSibling
                  button.onclick = (): void => onClickBtnHandler(button)
                }
              }

              resizeHandler()
              const debouncedResizeHandler = debounce(50, false, () =>
                nextTick(() => resizeHandler(true))
              )
              window.addEventListener('resize', debouncedResizeHandler)
            }
          })
      })
    })

    return {
      blockText,
    }
  },
})
