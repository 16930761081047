<template>
  <Nuxt :class="$options.themeClass" />
</template>

<script>
import { ThemeType } from '../contracts/Settings'

export default {
  name: 'Empty',
  themeClass: `theme-${ThemeType.primary}`,
}
</script>
