
import { defineComponent, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  components: {},
  props: {
    keyName: {
      type: String,
      default: 'id',
    },
    options: {
      type: Array,
      default: () => [],
    },
    pending: {
      type: Boolean,
      default: false,
    },
  },
  setup(_props, { emit }) {
    const value = ref(null)
    const select = (value) => {
      emit('select', value)
    }
    return {
      value,
      select,
    }
  },
})
