import { IFormContact } from '../contracts/IFormContactBlock'
import { LinkType } from '../contracts/LinkType'
import { Logger } from './Logger'

export const getHrefByLinkType = (
  link: IFormContact,
  useType: LinkType = LinkType.NotSet
): string | null => {
  const type: LinkType = link.type || useType

  switch (type) {
    case LinkType.Url: {
      return link.value
    }

    case LinkType.Tel: {
      return `tel:${link.value}`
    }

    case LinkType.Email: {
      return `mailto:${link.value}`
    }

    case LinkType.NotSet: {
      Logger.error('Ошибка getHrefByLinkType: у ссылки не указан тип!')
      return '#'
    }

    default: {
      Logger.error(
        'Ошибка getHrefByLinkType: неизвестный тип ссылки!',
        'link - ' + link
      )

      return null
    }
  }
}
