


















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import Link from './Link.vue'
import { SectionTag } from '@/contracts/SectionData'

export default defineComponent({
  name: 'BlockSectionTags',
  components: { Link },
  props: {
    list: {
      type: Array as PropType<SectionTag[]>,
      default: () => [],
    },
  },
})
