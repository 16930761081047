
























































































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import { nextTick } from '@vue/composition-api'
import { DialogLink, DialogQuestion } from '@/store/popupSearchBot'
import Link from '@/components/Link.vue'
import { SearchBotQuestionAnswerResult } from '@/contracts/api/QuerySearchBot/QuerySearchBot.Response'
import BlockText from '@/components/BlockText.vue'

export default defineComponent({
  name: 'SearchAnswer',
  components: { BlockText, Link },
  props: {
    text: {
      type: String,
      default: '',
    },
    questionList: {
      type: Array as PropType<DialogQuestion[]>,
      default: () => [],
    },
    links: {
      type: Array as PropType<DialogLink[]>,
      default: () => [],
    },
    result: {
      type: Object as PropType<SearchBotQuestionAnswerResult>,
      default: () => ({}),
    },
  },
  setup(_, { emit }) {
    const onSelectQuestion = (question: DialogQuestion) => {
      emit('select-question', question)
    }

    const isShowMore = ref<boolean>(false)
    const visibleCount = ref<number>(0)

    const handleShowMore = () => {
      isShowMore.value = true
      visibleCount.value = 0
      const interval = setInterval(() => {
        if (visibleCount.value < 10) {
          visibleCount.value++
          nextTick(() => {
            emit('scroll-to-bottom')
          })
        } else {
          clearInterval(interval)
        }
      }, 150)
      nextTick(() => {
        emit('scroll-to-bottom')
      })
    }

    return {
      onSelectQuestion,
      handleShowMore,
      isShowMore,
      visibleCount,
    }
  },
})
