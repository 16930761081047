<template>
  <f-layout class="error-page">
    <template v-if="statusCode !== 500" #page-header>
      <error-header class="error-header" />
    </template>
    <template #page-content>
      <f-section class="error-section">
        <f-container class="error-container">
          <h1 class="title--h1 title">{{ data.title }}</h1>
          <p class="text--basic description">{{ data.description }}</p>
          <h2 class="title--huge subtitle">{{ data.code }}</h2>

          <ul v-if="data.list" class="links-list">
            <li v-for="(link, i) in data.list" :key="i" class="link-list-item">
              <Link :url="link.url" class="link-item text--basic-bold">
                {{ link.title }}
                <svg-icon name="icon/arrow short right" class="svg-icon" />
              </Link>
            </li>
          </ul>
        </f-container>
      </f-section>
    </template>
  </f-layout>
</template>

<script>
import ErrorHeader from '@/views/ErrorHeader'
import Link from '@/components/Link'
const DATA = {
  404: {
    title: 'Страница не найдена',
    description:
      'Возможно, она была перемещена, или вы просто\nневерно указали адрес страницы',
    links: [
      { title: 'Перейти на главную страницу', url: 'https://www.rgs.ru/' },
      {
        title: 'Задать вопрос Росгосстраху',
        url: 'https://my.rgs.ru/complaints.wbp',
      },
      {
        title: 'Узнать контакты компании',
        url: 'https://www.rgs.ru/about/kontakty',
      },
    ],
  },
  500: {
    title: 'Ошибка сервера',
    description:
      'Кажется, что-то пошло не так во внутренних\n системах. Попробуйте немного подождать',
  },
}

export default {
  components: {
    ErrorHeader,
    Link,
  },
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    statusCode() {
      const { statusCode } = this.error || {}
      return statusCode
    },

    data() {
      const data = this.statusCode === 404 ? DATA['404'] : DATA['500']

      return {
        title: data.title,
        description: data.description,
        list: data.links,
        code: this.statusCode,
      }
    },
  },
  beforeMount() {
    this.$store.commit('intendet/SET_INTENDET', false)
  },

  onBeforeUnmount() {
    this.$store.commit('intendet/SET_INTENDET', true)
  },
}
</script>

<style lang="scss" scoped>
.links-list {
  padding: 0;
  margin: 4.8rem 0 0 0;

  .link-list-item {
    padding: 0;
    list-style-type: none;

    &:not(:last-child) {
      margin-bottom: 2.4rem;
    }
    .link-item {
      display: inline-flex;
      align-items: center;
      transition: opacity 0.25s ease;
      color: $BG_PRIMARY;

      @include desktop {
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}
.error-container {
  position: relative;
}
section.section.error-section {
  padding-top: 10rem;
  height: 94vh;
  padding-bottom: 4.8rem;
  .container.error-container {
    min-height: auto;
    height: 100%;
  }
  @include mobile {
    padding-top: 6rem;
    padding-bottom: 2.4rem;
  }
}
.title {
  margin-bottom: 2.4rem;
  @include mobile {
    margin-bottom: 1.6rem;
  }
}

.svg-icon {
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 0.4rem;
  flex-shrink: 0;
}

.subtitle {
  color: $PRIMARY;
  position: absolute;
  bottom: 0;
  right: 0;
}
.description,
.title {
  color: $BG_PRIMARY;
  @include desktop {
    white-space: pre-line;
  }
}

.error-page {
  background-color: $PRIMARY_HOVER;
}
</style>
