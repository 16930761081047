
















































import { defineComponent, ref, useStore } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'SearchInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const store = useStore()
    const input = ref<HTMLInputElement | null>(null)
    const onInput = (event: InputEvent) => {
      const target = event.target as HTMLInputElement
      emit('input', target.value)
    }

    const onClick = () => {
      store.commit('popupSearchBot/SET_IS_SHOWN', true)
    }

    const onSubmit = () => {
      input.value.value = ''
      emit('submit')
    }

    return {
      input,
      onInput,
      onSubmit,
      onClick,
    }
  },
})
