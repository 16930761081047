










import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class Dummy extends Vue {
  public get propses(): Record<string, string> {
    return this.$attrs
  }
}
