
















































import {
  computed,
  defineComponent,
  PropType,
  ref,
  toRefs,
  useStore,
} from '@nuxtjs/composition-api'
import VueSkeletonLoader from 'skeleton-loader-vue'

export default defineComponent({
  name: 'Load',
  components: { VueSkeletonLoader },
  props: {
    loading: {
      type: Boolean,
      default: undefined,
    },
    justifyCenter: {
      type: Boolean,
      default: false,
    },
    keys: {
      type: Array as PropType<string[]>,
      default: () => ['empty'] as string[],
    },
    isNewLoader: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { loading } = toRefs(props)
    const loadKeys = ref<string[]>(
      props.keys.map((v) => (v === '' ? 'empty' : v))
    )
    const store = useStore()

    const loadStatus = computed<boolean>(() => {
      if (loading.value !== undefined) {
        return loading.value
      }

      return (
        !!store.getters['loader/loaderStatus'] &&
        store.getters['loader/loaderStatus'](loadKeys.value)
      )
    })

    return {
      loadStatus,
    }
  },
})
