













































































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { defineComponent, SetupContext, computed } from '@vue/composition-api'
import { useHeaderHeight } from '@/composition/headerHeight'
import MenuRight from '@/components/Header/MenuRight.vue'
import ProductList from '@/components/Header/ProductList.vue'
import PopupFade from '@/components/PopupFade.vue'
import CardImage from '@/components/Cards/CardImage.vue'
import Link from '@/components/Link.vue'

@Component
class SectionTabs extends Vue {
  @Prop({ default: () => {} }) readonly data: Object = {}
  @Prop({
    default: () => ({
      productList: {
        onlineTitle: '',
        officesTitle: '',
        online: [],
        offices: [],
      },
    }),
  })
  readonly category: Object = {
    productList: {
      onlineTitle: '',
      officesTitle: '',
      online: [],
      offices: [],
    },
  }

  @Prop() readonly tabElement: any | undefined
  @Prop() readonly backToMenu: any | undefined
  @Prop({ default: false }) readonly activeMobileMenu: Boolean | undefined
  @Prop({ default: false }) readonly activeTabMenu: Boolean | undefined
  @Prop({ default: false }) readonly isMobileTablet: Boolean | undefined
  static options: any
}

const SectionTabsProps = SectionTabs.options.props

export default defineComponent({
  name: 'SectionTabs',
  components: {
    MenuRight,
    ProductList,
    PopupFade,
    CardImage,
    Link,
  },
  props: { ...SectionTabsProps },
  setup(_props: any, ctx: SetupContext) {
    const { headerHeight } = useHeaderHeight()
    const popupStyles = computed(() => {
      return {
        top: `${headerHeight.value}px`,
        height: `calc(100vh - ${headerHeight.value}px)`,
      }
    })
    const togglePopup = () => {
      ctx.emit('toggle-popup')
    }
    const closeTabMenu = () => {
      ctx.emit('hide-popup')
    }
    const startSearch = () => {
      ctx.emit('start-search')
    }
    return {
      togglePopup,
      closeTabMenu,
      popupStyles,
      startSearch,
    }
  },
})
